import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import BackWithImage from './components/BackgroundWithImage';
import Login from './pages/Login';
import Signin from './pages/Signin';
import MainMenu from './pages/MainMenu';
import './App.css'
import Residential from './pages/Residential';
import Commercial from './pages/Commercial';
import ServiceConsult from './pages/ServiceConsult';
import BillLoad from './pages/BillLoad';
import ElectricidadResidencialCarga from './pages/ElectricidadResidencialCarga';
import ElectricidadComercialCarga from './pages/ElectricidadComercialCarga';
import GasCarga from './pages/GasCarga';
import ContratoDatosResidencial from './pages/ContratoDatosResidencial';
import ContratoDatosComercial from './pages/ContratoDatosComercial';
import ContratoTarifaaccesoServiciocontratado from './pages/ContratoTarifaaccesoServiciocontratado';
import Agradecimiento from './pages/Agradecimiento';
import RecommendationPage from './pages/RecommendationPage';
import CargarDatos2TD from './pages/CargarDatos2TD';
import CargarDatos3o6TD from './pages/CargarDatos3o6TD';
import CargarDatosGas from './pages/CargarDatosGas';
import CargarDocumento from './pages/CargarDocumento';
import {Button, Modal, Row} from 'antd';
import NotFoundPage from './components/NotFoundPage';
import AdminRoute from './utils/AdminRoute';
import UserControl from './pages/UsersControl';
import {WhatsAppOutlined} from '@ant-design/icons';
import AddProduct from "./pages/AddProduct";
import ManageProducts from "./pages/ManageProducts";
import Clients from './pages/Clients';
import PrivateRoute from "./utils/PrivateRoute";
import ReportRecommendation from './pages/ReportRecommendation';

// @ts-ignore
import ReactWhatsappButton from 'react-whatsapp-button';



function RouterWrapper() {
  const navigate = useNavigate();
  const selectedUsuario = localStorage.getItem('selectedUsuario');
  const selectedService = localStorage.getItem('selectedService')

  useEffect(() => {
    if ((!selectedUsuario || !selectedService) &&
      (window.location.pathname.includes('residencial') ||
        window.location.pathname.includes('comercial') ||
        window.location.pathname.includes('gas') ||
        window.location.pathname.includes('electricidad') ||
        window.location.pathname.includes('recomendacion') ||
        window.location.pathname.includes('factura'))) {
      navigate('/404');
    } else {
      if (selectedUsuario === 'residencial' && window.location.pathname.includes('comercial')) {
        navigate('/404');
      } else if (selectedUsuario === 'comercial' && window.location.pathname.includes('residencial')) {
        navigate('/404');
      }
      if (selectedService === 'electricidad' && window.location.pathname.includes('gas')) {
        navigate('/404');
      } else if (selectedService === 'gas' && window.location.pathname.includes('electricidad')) {
        navigate('/404');
      }
    }
  }, [selectedUsuario, selectedService, navigate]);
  return null;
}
function App() {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 7 * 60 * 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const storedTimestamp = localStorage.getItem('timestamp');
    if (storedTimestamp) {
      const timestampDiff = new Date().getTime() - parseInt(storedTimestamp, 10);
      const horasEnMillis = 24 * 60 * 60 * 1000;

      if (timestampDiff >= horasEnMillis) {
        localStorage.clear();
      }
    }
  }, []);

  const message = "¡Hola ElectryConsulting! necesito ayuda";

  return (
    <Router>
      <div className="App">
        <BackWithImage />
        <ReactWhatsappButton
          message={message}
          countryCode="34"
          phoneNumber="655794576"
          animated
        />
        <RouterWrapper />
        <Routes>
          <Route path='/' element={<MainMenu />} />

          <Route path='/login' element={<Login />} />
          <Route path='/signin' element={<AdminRoute children={<Signin />} />} />

          <Route path='/servicioaconsultar' element={<AdminRoute children={<ServiceConsult />}/>} />
          <Route path='/clientes' element={<PrivateRoute children={<Clients/>}/>}/>
          <Route path='/recomendacion/:id' element={<PrivateRoute children={<ReportRecommendation/>}/>}/>

          <Route path='/usuarios' element={<AdminRoute children={<UserControl  />}/> } />
          <Route path='/products/add' element={<AdminRoute children={<AddProduct />}/>}  />
          <Route path='/products/manage' element={<AdminRoute children={<ManageProducts  />}/> } />

          <Route path='/residencial' element={<Residential />} />
          <Route path='/comercial' element={<Commercial />} />
          <Route path='/factura' element={<BillLoad />} />
          <Route path='/factura/cargardocumento' element={<CargarDocumento />} />
          <Route path='/residencial/electricidad' element={<ElectricidadResidencialCarga />} />
          <Route path='/comercial/electricidad' element={<ElectricidadComercialCarga />} />
          <Route path='/residencial/gas' element={<GasCarga />} />
          <Route path='/comercial/gas' element={<GasCarga />} />
          <Route path='/residencial/electricidad/2.0TD' element={<CargarDatos2TD />} />
          <Route path='/comercial/electricidad/2.0TD' element={<CargarDatos2TD />} />
          <Route path='/comercial/electricidad/3.0TD' element={<CargarDatos3o6TD />} />
          <Route path='/comercial/electricidad/6.1TD' element={<CargarDatos3o6TD />} />
          <Route path='/residencial/gas/carga' element={<CargarDatosGas />} />
          <Route path='/comercial/gas/carga' element={<CargarDatosGas />} />

          <Route path='/recomendacion' element={<RecommendationPage />} />

          <Route path='/residencial/contrato/cliente' element={<ContratoDatosResidencial />} />
          <Route path='/comercial/contrato/cliente' element={<ContratoDatosComercial />} />
          <Route path='/residencial/contrato/tarifaacceso' element={<ContratoTarifaaccesoServiciocontratado />} />
          <Route path='/comercial/contrato/tarifaacceso' element={<ContratoTarifaaccesoServiciocontratado />} />

          <Route path='/agradecimiento' element={<Agradecimiento />} />

          <Route path='*' element={<NotFoundPage />} />

        </Routes>
        <Modal
          title="¿Estas teniendo complicaciones?"
          open={showModal}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={[
            <Row className='buttonsRow'><Button
              className='modalbutton'
              onClick={handleCloseModal}
            >
              Continuar trámite
            </Button>
              <Button
                className='modalbuttonwpp'
                onClick={handleCloseModal}
              >
                <a className='pointer' target="_blank" href='https://api.whatsapp.com/send/?phone=34655794576&text=&text=%C2%A1Hola%20ElectryConsulting!%20necesito%20ayuda'>Contactar <WhatsAppOutlined /></a>
              </Button></Row>

          ]}
        >
          <p>Recuerda que puedes contactarnos en cualquier momento.</p>
        </Modal>
      </div>
    </Router>
  );
}

export default App;
