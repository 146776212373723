import '../assets/styles/Login.css';
import {Button, Card, Form, Input, message} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import type {SizeType} from 'antd/es/config-provider/SizeContext';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from "universal-cookie";


interface UserData {
  users: string[];
}
function Login(): JSX.Element {
  const [size, setSize] = useState<SizeType>('middle');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState<UserData | null>(null);
  const cookies = new Cookies();
  const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;


  const handleFormSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const userData = {
          username: values.Usuario,
          password: values.Contrasenia
        };
        try {
          const response = await axios.post(`${REACT_APP_BFF_URL}/api/auth/login`, userData);
          if (response.status === 200) {
            const expirationTime = new Date();
            expirationTime.setDate(expirationTime.getDate() + 30);
            cookies.set("access-token", response.data.accessToken, {
              expires: expirationTime,
              secure: true,
              sameSite: "none",
            })
            message.success('Inició sesión correctamente');
            navigate('/servicioaconsultar');
            window.location.reload();
          } else {
            message.error('Los datos son incorrectos');
          }
        } catch (error) {
          console.error('Error during login:', error);
          message.error('Los datos son incorrectos');
        }
      })
      .catch((error) => {
        console.error('Form validation error:', error);
      });
  };

  return (
    <Form
      form={form}
      name="control-hooks"
    >
      <Card className="card-iniciar-sesion" bordered={true}>
        Iniciar Sesión
        <Form.Item name="Usuario" rules={[{ required: true, message: 'Ingrese su usuario' }]}>
          <Input
            placeholder="Usuario"
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item name="Contrasenia" rules={[{ required: true, message: 'Ingrese su contraseña' }]}>
          <Input.Password
            placeholder="Contraseña"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Button type="primary" shape="round" size={size} className="button" htmlType="submit" onClick={handleFormSubmit}>
          Iniciar
        </Button>

      </Card>
    </Form>
  );
}

export default Login;
