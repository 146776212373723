import '../assets/styles/UserIndividualCard.css';
import {Card, Col, message, Popconfirm, Row} from 'antd';
import {useEffect, useState} from 'react';
import Cookies from "universal-cookie";

interface User {
    _id: string;
    username: string;
    role: string;
}

interface UserIndividualCardProps {
    user: User;
    onDelete: (username: string) => void;
}

export const UserIndividualCard = ({ user, onDelete }: UserIndividualCardProps) => {
    const cookies = new Cookies();
    const cookie = cookies.get('access-token');
    const userName = JSON.parse(atob(cookie.split('.')[1])).username;
    const [screenSize, setScreenSize] = useState<string>('');
    const isCurrentUser = user.username === userName;


    const handleDeleteUser = (username: string) => {
        onDelete(username);
        message.success('Usuario eliminado exitosamente')
    };

    const confirm = (username: string) => {
        handleDeleteUser(username)
    };
    
    const cancel = () => {
        message.error('Se canceló la eliminación');
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 800) {
                setScreenSize('extrasmallscreen');
            } else if (width < 1150) {
                setScreenSize('smallscreen');
            } else {
                setScreenSize('');
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    return (
        <>
            <Card className='userRow'>
                <Row key={user._id}  >
                    <Col md={10} sm={7} xs={7} className='username-col'>
                        {screenSize === 'smallscreen' && (
                            <a>Usuario:<a className='username'>{user.username}</a></a>
                        )}
                        {screenSize === 'extrasmallscreen' && (
                            <a className='username'>{user.username}</a>
                        )}
                        {screenSize === '' && (
                            <a>Nombre del usuario:<a className='username'>{user.username}</a></a>
                        )}
                    </Col>
                    <Col className='separator'>
                    </Col>
                    <Col span={7} className='role-col'>
                        {screenSize === 'smallscreen' && (
                            <a>Rol:<a className='role'>{user.role}</a></a>
                        )}
                        {screenSize === 'extrasmallscreen' && (
                            <a className='role'>{user.role}</a>
                        )}
                        {screenSize === '' && (
                            <a>Rol del usuario:<a className='role'>{user.role}</a></a>
                        )}
                    </Col>
                    <Col className='separator'>
                    </Col>
                    <Col className='delete-col'>
                        {!isCurrentUser && (
                            <Card className='eliminateCard'>
                                <Popconfirm
                                    title="Eliminar Usuario"
                                    description="Seguro que desea eliminarlo?"
                                    onConfirm={() => confirm(user.username)}
                                    onCancel={cancel}
                                    okText="Si"
                                    cancelText="No"
                                    className='delete'
                                >
                                    <a className='delete'>{screenSize !== '' ? 'Eliminar' : 'Eliminar usuario'}</a>
                                </Popconfirm>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default UserIndividualCard;