import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getRecommendationById } from '../services/RecommendationServices';
import Loading from '../components/Loading';
import '../assets/styles/ReportRecommendation.css';

interface Recommendation {
  _id: string;
  recommendations: Array<{
    bill_id: string;
    product_id: string;
    bill_marketer: string;
    cups: string;
    product_marketer: string;
    product_name: string;
    product_fare: string;
    service: string;
    service_type: string;
    savings: number;
    savings_pct: number;
    power?: {
      total_product: number;
      total_bill: number;
      savings: number;
      savings_pct: number;
      details: Array<{
        type: string | null;
        product_projection: number;
        bill_projection: number;
        product_price: number;
        bill_price: number;
        quantity: number;
        savings: number;
        savings_pct: number;
      }>;
    };
    gas?: {
      total_product: number;
      total_bill: number;
      savings: number;
      savings_pct: number;
      details: Array<{
        type: string | null;
        product_projection: number;
        bill_projection: number;
        product_price: number;
        bill_price: number;
        quantity: number;
        savings: number;
        savings_pct: number;
      }>;
    };
    energy?: {
      total_product: number;
      total_bill: number;
      savings: number;
      savings_pct: number;
      details: Array<{
        type: string | null;
        product_projection: number;
        bill_projection: number;
        product_price: number;
        bill_price: number;
        quantity: number;
        savings: number;
        savings_pct: number;
      }>;
    };
  }>;
  selectedProduct: number;
}

const ReportRecommendation = (): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const [recommendation, setRecommendation] = useState<Recommendation | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendation = async (recommendationId: string) => {
      try {
        const recommendationData = await getRecommendationById(recommendationId);
        if (recommendationData) {
          setRecommendation(recommendationData);
          console.log(recommendation)
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch recommendation:", error);
      }
    };

    if (id) {
      fetchRecommendation(id);
    } else {
      console.error("No ID provided in the URL");
    }
  }, [id]);

  return (
    <Container>
      <Card className="recommendation-card" bordered={true}>
        {loading ? (
          <Loading />
        ) : (
          recommendation && (
            <div className="recommendation-content">
              <h2 className="center">Recomendación seleccionada</h2>
              {recommendation.recommendations[recommendation.selectedProduct] && (
                <div className="recommendation-details">
                  <h3>{recommendation.recommendations[recommendation.selectedProduct].product_name}</h3>
                  <div className="recommendation-row">
                    <div className="recommendation-cell">
                      <strong>Tipo servicio:</strong> {recommendation.recommendations[recommendation.selectedProduct].service_type}
                    </div>
                    <div className="recommendation-cell">
                      <strong>CUPS:</strong> {recommendation.recommendations[recommendation.selectedProduct].cups}
                    </div>
                  </div>
                  <div className="recommendation-row">
                    <div className="recommendation-cell">
                      <strong>Comercializadora:</strong> {recommendation.recommendations[recommendation.selectedProduct].product_marketer}
                    </div>
                    <div className="recommendation-cell">
                      <strong>Tarifa:</strong> {recommendation.recommendations[recommendation.selectedProduct].product_fare}
                    </div>
                  </div>
                  <div className="recommendation-row">
                    <div className="recommendation-cell">
                      <strong>Ahorro:</strong> {recommendation.recommendations[recommendation.selectedProduct].savings}€
                    </div>
                    <div className="recommendation-cell">
                      <strong>Porcentaje de ahorro:</strong> {recommendation.recommendations[recommendation.selectedProduct].savings_pct}%
                    </div>
                  </div>
                  {recommendation.recommendations[recommendation.selectedProduct].service === 'Electricidad' && (
                    <>
                      <h4>Detalles de Potencia</h4>
                      {(recommendation.recommendations[recommendation.selectedProduct].power?.details || []).map((detail, index) => (
                        <div key={index} className="recommendation-row">
                          <div className="recommendation-cell"><strong>Tipo:</strong> {detail.type}</div>
                          <div className="recommendation-cell"><strong>Proyección Producto:</strong> {detail.product_projection}</div>
                          <div className="recommendation-cell"><strong>Proyección Factura:</strong> {detail.bill_projection}</div>
                          <div className="recommendation-cell"><strong>Precio Producto:</strong> {detail.product_price}</div>
                          <div className="recommendation-cell"><strong>Precio Factura:</strong> {detail.bill_price}</div>
                          <div className="recommendation-cell"><strong>Cantidad:</strong> {detail.quantity}</div>
                          <div className="recommendation-cell"><strong>Ahorro:</strong> {detail.savings}€</div>
                          <div className="recommendation-cell"><strong>Porcentaje de ahorro:</strong> {detail.savings_pct}%</div>
                        </div>
                      ))}
                      <h4>Detalles de Energía</h4>
                      {(recommendation.recommendations[recommendation.selectedProduct].energy?.details || []).map((detail, index) => (
                        <div key={index} className="recommendation-row">
                          <div className="recommendation-cell"><strong>Tipo:</strong> {detail.type}</div>
                          <div className="recommendation-cell"><strong>Proyección Producto:</strong> {detail.product_projection}</div>
                          <div className="recommendation-cell"><strong>Proyección Factura:</strong> {detail.bill_projection}</div>
                          <div className="recommendation-cell"><strong>Precio Producto:</strong> {detail.product_price}</div>
                          <div className="recommendation-cell"><strong>Precio Factura:</strong> {detail.bill_price}</div>
                          <div className="recommendation-cell"><strong>Cantidad:</strong> {detail.quantity}</div>
                          <div className="recommendation-cell"><strong>Ahorro:</strong> {detail.savings}€</div>
                          <div className="recommendation-cell"><strong>Porcentaje de ahorro:</strong> {detail.savings_pct}%</div>
                        </div>
                      ))}
                    </>
                  )}
                  {recommendation.recommendations[recommendation.selectedProduct].service === 'Gas' && (
                    <>
                      <h4>Detalles de Gas</h4>
                      {(recommendation.recommendations[recommendation.selectedProduct].gas?.details || []).map((detail, index) => (
                        <div key={index} className="recommendation-row">
                          <div className="recommendation-cell"><strong>Proyección Producto:</strong> {detail.product_projection}</div>
                          <div className="recommendation-cell"><strong>Proyección Factura:</strong> {detail.bill_projection}</div>
                          <div className="recommendation-cell"><strong>Precio Producto:</strong> {detail.product_price}</div>
                          <div className="recommendation-cell"><strong>Precio Factura:</strong> {detail.bill_price}</div>
                          <div className="recommendation-cell"><strong>Cantidad:</strong> {detail.quantity}</div>
                          <div className="recommendation-cell"><strong>Ahorro:</strong> {detail.savings}€</div>
                          <div className="recommendation-cell"><strong>Porcentaje de ahorro:</strong> {detail.savings_pct}%</div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          )
        )}
      </Card>
    </Container>
  );
}

export default ReportRecommendation;
