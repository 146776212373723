import "../assets/styles/RecommendBox.css";
import { Spin } from 'antd';

export const RecommendBox = ({ recommendationData }: { recommendationData: any }): JSX.Element => {
  return (
    <div className="box leftbox">
      <div className="overlap-group-actual">
        {recommendationData ? (
          <>
            <div className="overlap-actual">
              <div className="text-wrapper">SERVICIO ACTUAL</div>
            </div>
            <div className="div">{recommendationData.bill_marketer || "Empresa actual"}</div>
            <div className="text-wrapper-7">Potencia</div>
            <div className="text-wrapper-8"><span className="grey">{recommendationData?.power?.total_bill || "Potencia "}€</span></div>
            <div className="text-wrapper-7">Energia</div>
            <div className="text-wrapper-8"><span className="grey">{recommendationData?.energy?.total_bill || "Energia "}€</span></div>
            <div className="text-wrapper-7">Total factura</div>
            <div className="text-wrapper-6"><span className="grey">{recommendationData?.total?.bill || "Total "}€</span></div>
          </>
        ) : (
          <div className="spinner">
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecommendBox;
