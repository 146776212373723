const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const getUserRole = async (userId: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/auth/role/${userId}`, {credentials: "include"});
    if (!response.ok) {
      throw new Error('Failed to fetch user role');
    }
    const data = await response.json();
    return data.role;
  } catch (error) {
    console.error('Error fetching user role:', error);
    return null;
  }
};

export const getUserId = async (userName: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/auth/id/${userName}`, {credentials: "include"});
    if (!response.ok) {
      throw new Error('Failed to fetch user id');
    }
    const data = await response.json();
    return data.id;
  } catch (error) {
    console.error('Error fetching user id:', error);
    return null;
  }
};

export const getUsers = async () => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/auth/users`, {credentials: "include"});
    if (!response.ok) {
      throw new Error('Failed to fetch users');
    }
    const users = await response.json();
    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    return null;
  }
};

export const deleteUser = async (userName: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/auth/delete/${userName}`, {
          method: 'DELETE',
          credentials: "include"
      });
      if (!response.ok) {
          throw new Error('Failed to delete user');
      }
      console.log('User deleted successfully');
      return true;
  } catch (error) {
      console.error('Error deleting user:', error);
      return false;
  }
};