import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Input } from 'antd';
import { Container } from 'react-bootstrap';
import { getReports } from '../services/ReportServices';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import '../assets/styles/Clients.css';

interface Report {
  _id: string;
  first_name: string;
  last_name: string;
  user_type: string;
  phone_number: string;
  email: string;
  recommendations_id: string;
  timestamp: string;
}

type OnChange = NonNullable<TableProps<Report>['onChange']>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const Clients = (): JSX.Element => {
  const [reports, setReports] = useState<Report[]>([]);
  const [filteredReports, setFilteredReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchReports() {
      try {
        const reportsData = await getReports();
        console.log(reportsData)
        if (reportsData) {
          setReports(reportsData);
          setFilteredReports(reportsData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch reports:", error);
      }
    }
    fetchReports();
  }, []);

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const handleBack = () => {
    navigate('/servicioaconsultar');
};  
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const filteredData = reports.filter(report =>
      `${report.first_name} ${report.last_name}`.toLowerCase().includes(value) ||
      report.user_type.toLowerCase().includes(value) ||
      report.phone_number.toLowerCase().includes(value) ||
      report.email.toLowerCase().includes(value)
    );
    setFilteredReports(filteredData);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const columns: TableColumnsType<Report> = [
    {
      title: 'Nombre',
      key: 'full_name',
      render: (text: string, record: Report) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      render: (timestamp: string) => formatDate(timestamp),
    },
    {
      title: 'Tipo',
      dataIndex: 'user_type',
      key: 'user_type',
      filters: [
        { text: 'Comercial', value: 'comercial' },
        { text: 'Residencial', value: 'residencial' },
      ],
      filteredValue: filteredInfo.user_type || null,
      onFilter: (value, record) => record.user_type.includes(value as string),
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Recomendacion',
      key: 'recommendations_id',
      render: (text: string, record: Report) => (
        <Button
          type="primary"
          onClick={() => navigate(`/recomendacion/${record.recommendations_id}`)}
        >
          Ver Recomendación
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <Card className="cardreports" bordered={true}>
        <CloseOutlined className='fixed-cross' onClick={handleBack} />
        <a>Aquí están todos tus reportes</a>
        <Input
          placeholder="Buscar reportes"
          size="large"
          onChange={handleSearch}
          style={{ marginBottom: 20, marginTop: 20 }}
        />
        {loading ? <Loading /> : (
          <Table 
            columns={columns} 
            dataSource={filteredReports} 
            rowKey="_id" 
            pagination={{ pageSize: 10 }} 
            onChange={handleChange}
            className="report-table"
          />
        )}
      </Card>
    </Container>
  );
}

export default Clients;
