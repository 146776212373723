import '../assets/styles/CargarDatos2TD.css';
import { Form, Card, Col, Row, Popover, Input } from 'antd';
import { Container } from 'react-bootstrap';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { jsonFormatelec36 } from '../services/JsonCreation';

interface ChargeRow {
  rowKey: number;
  value: string;
  CargoNormAmount?: string;
}
interface DiscountRow {
  rowKey: number;
  value: string;
  DiscountAmount?: string;
}
interface ServicesAndOthersRow {
  rowKey: number;
  value: string;
}
function CargarDatos3o6TD(): JSX.Element {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [discountRows, setDiscountRows] = useState<DiscountRow[]>([]);
  const [chargeRows, setChargeRows] = useState<ChargeRow[]>([]);
  const [ServicesAndOthersRows, setServicesAndOthersRows] = useState<ServicesAndOthersRow[]>([]);
  const [showExcessButtons, setShowExcessButtons] = useState(false);
  const [selectedOptionExcess, setSelectedOptionExcess] = useState<number | null>(null);
  const [showReactive, setShowReactive] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [ivaValue, setIvaValue] = useState(0);
  const [messageDisounts, setmessageDisounts] = useState(false);
  const [messageExess, setmessageExess] = useState(false);
  const [messageReactive, setmessageReactive] = useState(false);
  const [messageOthers, setmessageOthers] = useState(false);
  const [equipodemedidaprice, setequipodemedidaprice] = useState<number | undefined>(undefined);
  const [punta, setPunta] = useState<string>('P1');
  const [llano, setLlano] = useState<string>('P2');

  useEffect(() => {
    const periodofacturado = localStorage.getItem('formDataElectricidadCarga Comercial');
    if (periodofacturado) {
      const parsedData = JSON.parse(periodofacturado);
      const finalDate = new Date(parsedData.final);
      const month = finalDate.getMonth() + 1;

      let puntaValue = '0';
      let llanoValue = '0';

      switch (month) {
        case 12:
        case 1:
        case 2:
        case 7:
          puntaValue = 'P 1';
          llanoValue = 'P 2';
          break;
        case 3:
        case 11:
          puntaValue = 'P 2';
          llanoValue = 'P 3';
          break;
        case 6:
        case 8:
        case 9:
          puntaValue = 'P 3';
          llanoValue = 'P 4';
          break;
        case 4:
        case 5:
        case 10:
          puntaValue = 'P 4';
          llanoValue = 'P 5';
          break;
        default:
          break;
      }

      setPunta(puntaValue);
      setLlano(llanoValue);
    } else {
      console.log("No data found in localStorage for 'formDataElectricidadCarga Comercial'");
    }
  }, []);

  const [powerP1, setPowerP1] = useState('');
  const [powerP2, setPowerP2] = useState('');
  const [powerP3, setPowerP3] = useState('');
  const [powerP4, setPowerP4] = useState('');
  const [powerP5, setPowerP5] = useState('');
  const [powerP6, setPowerP6] = useState('');
  const [powerConsumida1, setPowerConsumida1] = useState('');
  const [powerConsumida2, setPowerConsumida2] = useState('');
  const [powerConsumida3, setPowerConsumida3] = useState('');
  const [powerExesos1, setPowerExesos1] = useState('');
  const [powerExesos2, setPowerExesos2] = useState('');
  const [powerExesos3, setPowerExesos3] = useState('');
  const [powerReactiva1, setPowerReactiva1] = useState('');


  const [rateP1, setRateP1] = useState('');
  const [rateP2, setRateP2] = useState('');
  const [rateP3, setRateP3] = useState('');
  const [rateP4, setRateP4] = useState('');
  const [rateP5, setRateP5] = useState('');
  const [rateP6, setRateP6] = useState('');
  const [rateConsumida1, setRateConsumida1] = useState('');
  const [rateConsumida2, setRateConsumida2] = useState('');
  const [rateConsumida3, setRateConsumida3] = useState('');
  const [rateExesos1, setRateExesos1] = useState('');
  const [rateExesos2, setRateExesos2] = useState('');
  const [rateExesos3, setRateExesos3] = useState('');
  const [rateReactiva1, setRateReactiva1] = useState('');


  const [resultP1, setResultP1] = useState<string | null>(null);
  const [resultP2, setResultP2] = useState<string | null>(null);
  const [resultP3, setResultP3] = useState<string | null>(null);
  const [resultP4, setResultP4] = useState<string | null>(null);
  const [resultP5, setResultP5] = useState<string | null>(null);
  const [resultP6, setResultP6] = useState<string | null>(null);
  const [resultConsumida1, setResultConsumida1] = useState<string | null>(null);
  const [resultConsumida2, setResultConsumida2] = useState<string | null>(null);
  const [resultConsumida3, setResultConsumida3] = useState<string | null>(null);
  const [resultExesos1, setResultExesos1] = useState<string | null>(null);
  const [resultExesos2, setResultExesos2] = useState<string | null>(null);
  const [resultExesos3, setResultExesos3] = useState<string | null>(null);
  const [resultReactiva1, setResultReactiva1] = useState<string | null>(null);

  const handleequipodemedidapriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(e.target.value);
    if (!isNaN(inputValue)) {
      setequipodemedidaprice(inputValue);
    };
  };
  const contentguide = (
    <div className='divguide'>
      <p>Seleccione una opción.</p>
    </div>
  );
  const handleInputChange = (fieldName: string, value: string) => {
    form.setFieldsValue({ [fieldName]: value });
    const formValues = form.getFieldsValue();
    localStorage.setItem('formDataCarga3.0/6.1', JSON.stringify(formValues));
  };
  const updateResult = (
    power: string,
    rate: string,
    setResult: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    const result = (parseFloat(power) || 0) * (parseFloat(rate) || 0);
    setResult(result.toFixed(2));
  };
  const IVA = (values: string[]): number => {
    const threshold = 10;
    const lowerIVA = 10;
    const higherIVA = 21;

    const numericValues = values.map((value) => parseFloat(value)).filter((value) => !isNaN(value));

    if (numericValues.length < 6) {
      return 10;
    }

    const maxInputValue = Math.max(...numericValues);

    const iva = maxInputValue <= threshold ? lowerIVA : higherIVA;

    return iva;
  };
  const handlePowerChangeWithDays = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.value;
    switch (name) {
      case 'powerP1':
        handleInputChange(`powerP1`, value);
        setPowerP1(value);
        calculateResult(value, rateP1, setRateP1, setResultP1, 'rateP1');
        break;
      case 'powerP2':
        handleInputChange(`powerP2`, value);
        setPowerP2(value);
        calculateResult(value, rateP2, setRateP2, setResultP2, 'rateP2');
        break;
      case 'powerP3':
        handleInputChange(`powerP3`, value);
        setPowerP3(value);
        calculateResult(value, rateP3, setRateP3, setResultP3, 'rateP3');
        break;
      case 'powerP4':
        handleInputChange(`powerP4`, value);
        setPowerP4(value);
        calculateResult(value, rateP4, setRateP4, setResultP4, 'rateP4');
        break;
      case 'powerP5':
        handleInputChange(`powerP5`, value);
        setPowerP5(value);
        calculateResult(value, rateP5, setRateP5, setResultP5, 'rateP5');
        break;
      case 'powerP6':
        handleInputChange(`powerP6`, value);
        setPowerP6(value);
        calculateResult(value, rateP6, setRateP6, setResultP6, 'rateP6');
        break;
      case 'Exesos1Power':
        setPowerExesos1(value);
        handleInputChange('Exesos1Power', value);
        calculateResult(value, rateExesos1, setRateExesos1, setResultExesos1, 'Exesos1Rate');
        break;
      case 'Exesos2Power':
        setPowerExesos2(value);
        handleInputChange('Exesos2Power', value);
        calculateResult(value, rateExesos2, setRateExesos2, setResultExesos2, 'Exesos2Rate');
        break;
      case 'Exesos3Power':
        setPowerExesos3(value);
        handleInputChange('Exesos3Power', value);
        calculateResult(value, rateExesos3, setRateExesos3, setResultExesos3, 'Exesos3Rate');
        break;
      default:
    }
    
  };

  useEffect(() => {
    const allPowerValues = [powerP1, powerP2, powerP3, powerP4, powerP5, powerP6];
    setIvaValue(IVA(allPowerValues));
  }, [powerP1, powerP2, powerP3, powerP4, powerP5, powerP6]);


  const calculateResult = (
    power: string,
    rate: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    const calculatedResult = (Number(differenceInDays) * Number(power)) * Number(rate);
    const resultString = calculatedResult.toString();
    setRate(rate);
    setResult(resultString);
    handleInputChange(fieldName, rate);
  };
  const handleRateChangeWithDays = (
    e: React.ChangeEvent<HTMLInputElement>,
    power: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    const newRate = e.target.value;
    calculateResult(power, newRate, setRate, setResult, fieldName);
  };
  const handlePowerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setPower: React.Dispatch<React.SetStateAction<string>>,
    rate: string,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    setPower(e.target.value);
    updateResult(e.target.value, rate, setResult);
    handleInputChange(fieldName, e.target.value);
  };
  const handleRateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    power: string,
    setRate: React.Dispatch<React.SetStateAction<string>>,
    setResult: React.Dispatch<React.SetStateAction<string | null>>,
    fieldName: string
  ) => {
    setRate(e.target.value);
    updateResult(power, e.target.value, setResult);
    handleInputChange(fieldName, e.target.value);
  };
  const handleOptionButtonClickExcess = (option: number) => {
    setSelectedOptionExcess(option);
    setResultExesos1("0");
    setResultExesos2("0");
    setResultExesos3("0");
    setPowerExesos1("0");
    setPowerExesos2("0");
    setPowerExesos3("0");
    setRateExesos1("0");
    setRateExesos2("0");
    setRateExesos3("0");
    form.resetFields(["ExesosPower1", "ExesosRate1", "ExesosPower2", "ExesosRate2", "ExesosPower3", "ExesosRate3"]);
  };
  const handlePlusButtonClickExcess = () => {
    setmessageExess(false)
    setmessageReactive(true)
    setShowExcessButtons(true);
  };
  const handleLessButtonClickExcess = () => {
    setShowExcessButtons(false);
    setSelectedOptionExcess(null);
  };
  const handlePlusButtonClickReactive = () => {
    setmessageReactive(false);
    setmessageOthers(true)
    setShowReactive(true);
  };
  const handleLessButtonClickReactive = () => {
    setShowReactive(false);
    setPowerReactiva1("0");
    setResultReactiva1("0");
    setRateReactiva1("0");
  };
  const renderEnergiaConsumida = () => {
    if (selectedButton === 1) {
      return (
        <>
          <Form.Item
            name="consumida1Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida1, rateConsumida1, setResultConsumida1, 'consumida1Power')} /> kWh </a>
          </Form.Item>
          <Form.Item
            name="consumida1Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>&nbsp; x {" "}
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) => {
                  handleRateChange(e, powerConsumida1, setRateConsumida1, setResultConsumida1, 'consumida1Rate')
                  setmessageDisounts(true)
                }
                } />€/kWh </a>
          </Form.Item>
        </>
      );
    } else if (selectedButton === 3) {
      return (
        <>
          <Form.Item
            name="consumida1Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              {punta}:{" "}
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida1, rateConsumida1, setResultConsumida1, 'consumida1Power')} /> kWh x &nbsp;</a>
          </Form.Item>
          <Form.Item
            name="consumida1Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) => {
                  handleRateChange(e, powerConsumida1, setRateConsumida1, setResultConsumida1, 'consumida1Rate')
                }
                } />€/kWh</a></Form.Item>
        </>
      );
    }
    return "";
  };
  const handleAddDiscountRow = (): void => {
    setmessageDisounts(false)
    setmessageExess(true)
    if (discountRows.length < 1) {
      setDiscountRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
    }
  };
  const handleAddServicesAndOthersRow = (): void => {
    setmessageOthers(false)
    if (ServicesAndOthersRows.length < 1) {
      setServicesAndOthersRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
    }
  };
  const handleDeleteDiscountRow = (rowKey: number): void => {
    setDiscountRows((prevRows) => prevRows.filter((row) => row.rowKey !== rowKey));
  };
  const handleDiscountInputChange = (rowKey: number, inputValue: string, inputType: string): void => {
    setDiscountRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.rowKey === rowKey ? { ...row, [inputType]: inputValue } : row
      );
      const fieldName = `discountInput`;
      handleInputChange(fieldName, inputValue);

      return updatedRows;
    });
  };
  const handleServicesAndOthersInputChange = (rowKey: number, inputValue: string): void => {
    setServicesAndOthersRows((prevRows) => {
      return prevRows.map((row) =>
        row.rowKey === rowKey ? { ...row, value: inputValue } : row
      );
    });
  };
  const handleDeleteServicesAndOthersRow = (rowKey: number) => {
    setServicesAndOthersRows((prevRows) => prevRows.filter((row) => row.rowKey !== rowKey));
    form.setFieldsValue({
      [`ServiceAndOthersDescription`]: undefined,
      [`ServiceAndOthersAmount`]: undefined,
    });
  };
  const handleButtonClick = (buttonNumber: number) => {
    setSelectedButton(buttonNumber);
    form.setFieldsValue({
      'Periodos Facturados': buttonNumber,
    });
    setResultConsumida1("0");
    setResultConsumida2("0");
    setResultConsumida3("0");
    setPowerConsumida1("0");
    setPowerConsumida2("0");
    setPowerConsumida3("0");
    setRateConsumida1("0");
    setRateConsumida2("0");
    setRateConsumida3("0");
  };
  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      const formValues: { [key: string]: string } = form.getFieldsValue();
      const valuesArray = Object.values(formValues);
      const hasZeroValue = valuesArray.some(value => parseFloat(value) === 0);
      if (hasZeroValue) {
        setErrorModalVisible(true)
        return; 
      }
      localStorage.setItem('formDataCarga3.0/6.1', JSON.stringify(formValues));
      localStorage.setItem('total', total);
      jsonFormatelec36();
      navigate('/recomendacion');
    } catch (errorInfo) {
      console.error('Form incomplete:', errorInfo);
    }
  };


  const additionalRows = selectedButton === 3 ? (

    <>
      <Row className='verde'>
        <Col xs={0} lg={8} xl={8} className='contentleft'>
        </Col>
        <Col xs={19} lg={10} xl={10} className='contentleft'>

          <Form.Item
            name="consumida2Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold paddingmiddletoright'>
              {llano}: {" "}
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida2, rateConsumida2, setResultConsumida2, 'consumida2Power')}></input> kWh x &nbsp;</a>
          </Form.Item>
          <Form.Item
            name="consumida2Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handleRateChange(e, powerConsumida2, setRateConsumida2, setResultConsumida2, 'consumida2Rate')}></input>€/kWh</a>
          </Form.Item>
        </Col>
        <Col xs={2} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
          <a className='nobold'>{resultConsumida2 !== null ? `${resultConsumida2}€` : '___€'}</a>
        </Col>
      </Row>
      <Row className='verde'>
        <Col xs={0} lg={8} xl={8} className='contentleft'>
        </Col>
        <Col xs={19} lg={10} xl={10} className='contentleft'>
          <Form.Item
            name="consumida3Power"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold paddingmiddletoright'>P 6: {" "}
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) =>
                  handlePowerChange(e, setPowerConsumida3, rateConsumida3, setResultConsumida3, 'consumida3Power')}></input> kWh x &nbsp;</a>
          </Form.Item>
          <Form.Item
            name="consumida3Rate"
            className='height'
            rules={[
              { required: true, message: '' },
            ]}
          >
            <a className='nobold'>
              <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
                onChange={(e) => {
                  handleRateChange(e, powerConsumida3, setRateConsumida3, setResultConsumida3, 'consumida3Rate')
                  setmessageDisounts(true)
                }
                }></input>€/kWh</a>
          </Form.Item>
        </Col>
        <Col xs={2} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
          <a className='nobold'>{resultConsumida3 !== null ? `${resultConsumida3}€` : '___€'}</a>
        </Col>
      </Row>
    </>
  ) : null;
  const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
    console.log('Failed:', errorInfo);
    setErrorModalVisible(true);
  };
  const handleModalOk = () => {
    setErrorModalVisible(false);
  };
  const updateTotalSum = () => {
    const valuesToSum = [
      resultP1, resultP2, resultP3, resultP4, resultP5, resultP6, resultConsumida1, resultConsumida2, resultConsumida3,
      resultExesos1, resultExesos2, resultExesos3, resultReactiva1
    ];
    const filteredValues = valuesToSum
      .filter(value => value !== null)
      .map(value => parseFloat(value as string))
      .filter(value => !isNaN(value));

    const sum = filteredValues.reduce((acc, currentValue) => acc + currentValue, 0);
    setTotalSum(sum);
  };
  const calculateTotal = (): number => {
    const totalWithoutDiscount = chargeRows.reduce((total, row) => {
      const amount = parseFloat(row.CargoNormAmount || '0');
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    const discountTotal = discountRows.reduce((total, row) => {
      const amount = parseFloat(row.DiscountAmount || '0');
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    const additionalTotalSum = totalSum || 0;
    const bonosocialfijo = differenceInDays * 0.038455 || 0;
    const rawTotal = totalWithoutDiscount - discountTotal + additionalTotalSum + bonosocialfijo;
    const formattedTotal = Math.max(parseFloat(rawTotal.toFixed(2)), 0);
    return formattedTotal;
  };
  const calculateTotalOthers = (): number => {
    const totalOthers = ServicesAndOthersRows.reduce((total, row) => total + parseFloat(row.value) || 0, 0);
    return totalOthers
  };
  useEffect(() => {
    updateTotalSum();
  }, [resultP1, resultP2, resultP3, resultP4, resultP5, resultP6, resultConsumida1, resultConsumida2, resultConsumida3,
    resultExesos1, resultExesos2, resultExesos3, resultReactiva1]);
  useEffect(() => {
    const storedDataStringCom = localStorage.getItem('formDataElectricidadCarga Comercial');
    if (storedDataStringCom) {
      const storedData = JSON.parse(storedDataStringCom);
      const storedInicio = new Date(storedData.Inicio);
      const storedFin = new Date(storedData.final);
      const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
      const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      setDifferenceInDays(differenceInDays);
    }
  }, []);
  useEffect(() => {
    try {
      const storedFormData = localStorage.getItem('formDataCarga3.0/6.0');
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
        // form.setFieldsValue(parsedFormData);
      }
    } catch (error) {
      console.error('Error parsing stored form data:', error);
    }
  }, [form]);

  const tienediscount = (
    <div className='divguide'>
      <p>Tiene algun descuento?
      <button className='bottonsi' onClick={() => {
          setmessageDisounts(false)
          setmessageExess(true)
          if (discountRows.length < 1) {
            setDiscountRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
          }
        }}>Si</button>
        <button className='bottonno' onClick={() => {
          setmessageDisounts(false)
          setmessageExess(true)
        }}>No</button>
      </p>
    </div>
  );

  const tieneexess = (
    <div className='divguide'>
      <p>Tiene algun exeso?
      <button className='bottonsi' onClick={() => {
          setmessageExess(false)
          setmessageReactive(true)
          setShowExcessButtons(true)
        }}>Si</button>
        <button className='bottonno' onClick={() => {
          setmessageExess(false)
          setmessageReactive(true)
        }
        }>No</button>
      </p>
    </div>
  );
  const tienereactive = (
    <div className='divguide'>
      <p>Tiene energía reactiva?
      <button className='bottonsi' onClick={() => {
          setmessageReactive(false)
          setmessageOthers(true)
          setShowReactive(true)
        }}>Si</button>
        <button className='bottonno' onClick={() => {
          setmessageReactive(false)
          setmessageOthers(true)
        }}>No</button>
      </p>
    </div>
  );
  const tieneother = (
    <div className='divguide'>
      <p>Tiene otros conceptos?
      <button className='bottonsi' onClick={() => {
          setmessageOthers(false)
          if (ServicesAndOthersRows.length < 1) {
            setServicesAndOthersRows((prevRows) => [...prevRows, { rowKey: Date.now(), value: '' }]);
          }
        }}>Si</button>
        <button className='bottonno' onClick={() => {
          setmessageOthers(false)
        }}>No</button>
      </p>
    </div>
  );
  const potenciafacturadacontent = (
    <div className='divguide'>
      <p>Número de kilovatios de los que dispones en tu suministro.</p>
    </div>
  );
  const periodosfacturadoscontent = (
    <div className='divguide'>
      <p>Tramos en los que se divide el consumo de energía eléctrica.</p>
    </div>
  );
  const exesoscontent = (
    <div className='divguide'>
      <p>Cargo a pagar cuando el consumidor supera la potencia eléctrica contratada.</p>
    </div>
  );
  const reactivacontent = (
    <div className='divguide'>
      <p>Tipo de energía eléctrica que se absorbe de la red y luego se devuelve.</p>
    </div>
  );
  const bonosocialcontent = (
    <div className='divguide'>
      <p>Mecanismo de descuento fijado por el Gobierno de España. </p>
    </div>
  );
  const impuestocontent = (
    <div className='divguide'>
      <p>Impuesto especial que grava el coste de la fabricación de electricidad.</p>
    </div>
  );
  const alquilerquiposcontent = (
    <div className='divguide'>
      <p>Cantidad mensual que se pagar cuando el contador es propiedad de la empresa.</p>
    </div>
  );
  const ivacontent = (
    <div className='divguide'>
      <p>El Impuesto sobre el Valor Añadido constituye la base del sistema español de imposición indirecta.</p>
    </div>
  );
  const total = (
    (calculateTotal() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) +
    ((calculateTotal() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) * (ivaValue / 100)) +
    (calculateTotalOthers() + (calculateTotalOthers()) * (21 / 100))
  ).toFixed(2);
  return (
    <Container>
      <Form form={form} name="control-hooks" onFinishFailed={onFinishFailed}
      >
        <Card className="cardtable" bordered={true}>
          <Col>
            <Row className='contenttocenter paddbottomtitle'>
              <Col xs={24} lg={16} xl={16}>
                <a className="titlecard">Busca tu factura y completa el siguiente formulario</a>
              </Col>

            </Row>
            <Row className='verde'>
              <a className='negrita paddtextleft'>Energía</a>
            </Row>
            <Row className='blanco'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Potencia facturada</a>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="P1Power"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>
                    P 1:{" "}
                    <input
                      type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      value={powerP1}
                      onChange={(e) => handlePowerChangeWithDays(e, 'powerP1')}
                    /> kW
                  </a>
                </Form.Item>
                <Form.Item
                  name="P1Rate"
                  className='height inlineinput'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold '>
                    &nbsp; x {differenceInDays} días x{" "}
                    <input
                      type='number'
                      className='inputbuttonsize'
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleRateChangeWithDays(e, powerP1, setRateP1, setResultP1, 'rateP1')}
                    />€/kW día
                  </a>
                </Form.Item>
              </Col>
              <Col xs={24} lg={6} xl={6} className='contentright'>
                <a className='nobold'>{resultP1 !== null ? `${Number(resultP1).toFixed(2)}€` : '0€'}</a>
                <Popover content={potenciafacturadacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={0} lg={8} xl={8} className='contentleft'>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="P2Power"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>P 2: {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      value={powerP2}
                      onChange={(e) => handlePowerChangeWithDays(e, 'powerP2')}
                    ></input>  kW   </a>
                </Form.Item>
                <Form.Item
                  name="P2Rate"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> &nbsp; x {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) =>
                        handleRateChangeWithDays(e, powerP2, setRateP2, setResultP2, 'rateP2')
                      }
                    ></input>€/kW día</a>
                </Form.Item>
              </Col>
              <Col xs={21} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='nobold'>{resultP2 !== null ? `${Number(resultP2).toFixed(2)}€` : '0€'}</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <Col xs={0} lg={8} xl={8} className='contentleft'>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="P3Power"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>P 3: {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      value={powerP3}
                      onChange={(e) => handlePowerChangeWithDays(e, 'powerP3')}
                    ></input>  kW   </a>
                </Form.Item>
                <Form.Item
                  name="P3Rate"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> &nbsp; x {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) =>
                        handleRateChangeWithDays(e, powerP3, setRateP3, setResultP3, 'rateP3')
                      }
                    ></input>€/kW día</a>
                </Form.Item>
              </Col>
              <Col xs={21} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='nobold'>{resultP3 !== null ? `${Number(resultP3).toFixed(2)}€` : '0€'}</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={0} lg={8} xl={8} className='contentleft'>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="P4Power"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>P 4: {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      value={powerP4}
                      onChange={(e) => handlePowerChangeWithDays(e, 'powerP4')}
                    ></input>  kW   </a>
                </Form.Item>
                <Form.Item
                  name="P4Rate"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> &nbsp; x {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) =>
                        handleRateChangeWithDays(e, powerP4, setRateP4, setResultP4, 'rateP4')
                      }
                    ></input>€/kW día</a>
                </Form.Item>
              </Col>
              <Col xs={21} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='nobold'>{resultP4 !== null ? `${Number(resultP4).toFixed(2)}€` : '0€'}</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <Col xs={0} lg={8} xl={8} className='contentleft'>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="P5Power"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>P 5: {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      value={powerP5}
                      onChange={(e) => handlePowerChangeWithDays(e, 'powerP5')}
                    ></input>  kW   </a>
                </Form.Item>
                <Form.Item
                  name="P5Rate"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> &nbsp; x {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) =>
                        handleRateChangeWithDays(e, powerP5, setRateP5, setResultP5, 'rateP5')
                      }
                    ></input>€/kW día</a>
                </Form.Item>
              </Col>
              <Col xs={21} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='nobold'>{resultP5 !== null ? `${Number(resultP5).toFixed(2)}€` : '0€'}</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={0} lg={8} xl={8} className='contentleft'>
              </Col>
              <Col xs={24} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="P6Power"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright'>P 6: {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      value={powerP6}
                      onChange={(e) => handlePowerChangeWithDays(e, 'powerP6')}
                    ></input>  kW   </a>
                </Form.Item>
                <Form.Item
                  name="P6Rate"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold'> &nbsp; x {differenceInDays} días x {" "}
                    <input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) =>
                        handleRateChangeWithDays(e, powerP6, setRateP6, setResultP6, 'rateP6')
                      }
                    ></input>€/kW día</a>
                </Form.Item>
              </Col>
              <Col xs={21} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='nobold'>{resultP6 !== null ? `${Number(resultP6).toFixed(2)}€` : '0€'}</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <a className='negrita paddtextleft'>Energía consumida</a>
            </Row>
            <Row className={`verde `}>
              <Col xs={19} lg={8} xl={8} className='contentleft'>

                <a className='nobold paddtextleft'>Periodos facturados:  </a>
                <Form.Item
                  name="Periodos Facturados"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <Popover content={contentguide} visible={!selectedButton} placement="right">
                    <Button
                      className={`roundedbutton smaller-button ${selectedButton === 1 ? 'selected' : ''} ${selectedButton ? '' : 'no-button-selected'}`}
                      onClick={() => handleButtonClick(1)}
                    >
                      1
                    </Button>
                    <a className='nobold'>  ó  </a>
                    <Button
                      className={`roundedbutton smaller-button ${selectedButton === 3 ? 'selected' : ''} ${selectedButton ? '' : 'no-button-selected'}`}
                      onClick={() => handleButtonClick(3)}
                    >
                      3
                    </Button>
                  </Popover>
                </Form.Item>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'>{renderEnergiaConsumida()}</a>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                {(selectedButton === 1 || selectedButton === 3) && (<a className='nobold'>{resultConsumida1 !== null ? `${resultConsumida1}€` : '___€'}</a>)}
                <Popover content={periodosfacturadoscontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            {additionalRows}
            <Row className='blanco'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tienediscount} visible={messageDisounts} placement="right">
                  <a className='negrita paddtextleft'>Descuentos   </a>
                  <Button
                    className={`roundedbutton smaller-button`}
                    onClick={handleAddDiscountRow}
                  >
                    +
                  </Button>
                </Popover>
              </Col>
              <Col xs={0} lg={10} xl={10}>
              </Col>
              <Col xs={0} lg={6} xl={6} className='contentright'>
              </Col>
            </Row>
            {discountRows.map((row) => (
              <Row key={row.rowKey}
                className='blanco'
              >
                <Col xs={15} lg={8} xl={8} className='contentleft'>
                  <a className='nobold paddtextleft'>Monto total en Euros: {""} </a>
                </Col>
                <Col xs={0} lg={10} xl={10} className='contentleft'>

                </Col>
                <Col xs={9} lg={6} xl={6} className='contentright'>
                  <Form.Item
                    name={`DiscountAmount`}
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  >
                    <a className='nobold paddtextright'>-<input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) => handleDiscountInputChange(row.rowKey, e.target.value, 'DiscountAmount')}
                    ></input>€ </a>
                  </Form.Item>
                  <Button
                    className={`roundedbutton smaller-button paddtextrightnoquestion`}
                    onClick={() => handleDeleteDiscountRow(row.rowKey)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}
            <Row className='verde'>
              <Col xs={24} sm={15} lg={8} xl={8} className='contentleft'>
                <Popover content={tieneexess} visible={messageExess} placement="bottom">
                  <a className='negrita paddtextleft'>Excesos de Potencia   </a>
                  <Button className={`roundedbutton smaller-button`} onClick={handlePlusButtonClickExcess}> + </Button>
                </Popover>
              </Col>
              <Col xs={19} sm={24} lg={10} xl={10} className='contentleft'>

                {selectedOptionExcess === 1
                  ? <>
                    <Form.Item
                      name="ExesosPower1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    ><a className='nobold paddingmiddletoright'>P : {" "}
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handlePowerChangeWithDays(e, 'Exesos1Power')}
                        /> kW x {differenceInDays} días x &nbsp; </a>
                    </Form.Item>
                    <Form.Item
                      name="ExesosRate1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    >
                      <a className='nobold '>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          handleRateChangeWithDays(e, powerExesos1, setRateExesos1, setResultExesos1, 'Exesos1Rate')
                        } />€/kW día</a></Form.Item>
                  </>
                  : selectedOptionExcess === 2 || selectedOptionExcess === 3
                    ? <>
                      <Form.Item
                        name="ExesosPower1"
                        className='height'
                        rules={[
                          { required: true, message: '' },
                        ]}
                      ><a className='nobold paddingmiddletoright'>
                          P : {" "}
                          <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handlePowerChangeWithDays(e, 'Exesos1Power')} /> kW x {differenceInDays} días x &nbsp; </a>
                      </Form.Item>
                      <Form.Item
                        name="ExesosRate1"
                        className='height'
                        rules={[
                          { required: true, message: '' },
                        ]}
                      >
                        <a className='nobold '>
                          <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            handleRateChangeWithDays(e, powerExesos1, setRateExesos1, setResultExesos1, 'Exesos1Rate')
                          } />€/kW día</a>
                      </Form.Item>
                    </>
                    : ''}

              </Col>
              <Col xs={5} md={0} lg={6} xl={6} className='contentright'>
                {showExcessButtons && (selectedOptionExcess === 1 || selectedOptionExcess === 2 || selectedOptionExcess === 3) && (
                  <a className='nobold'>{resultExesos1 !== null ? `${resultExesos1}€` : '___€'}</a>
                )}
                <Popover content={exesoscontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            {showExcessButtons && (
              <Row className='verde'>
                <Col span={8} className='contentleft paddtextsoleft'>
                </Col>
                <Col xs={18} lg={10} xl={10} className='contentleft'>
                  {selectedOptionExcess === 1
                    ? ''
                    : selectedOptionExcess === 2 || selectedOptionExcess === 3
                      ? <>
                        <Form.Item
                          name="ExesosPower2"
                          className='height'
                          rules={[
                            { required: true, message: '' },
                          ]}
                        ><a className='nobold paddingmiddletoright'>
                            P : {" "}
                            <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                              if (e.key === '-' || e.key === 'e') {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => handlePowerChangeWithDays(e, 'Exesos2Power')}  /> kW x {differenceInDays} días x &nbsp; </a>
                        </Form.Item>
                        <Form.Item
                          name="ExesosRate2"
                          className='height'
                          rules={[
                            { required: true, message: '' },
                          ]}
                        ><a className='nobold '>
                            <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                              if (e.key === '-' || e.key === 'e') {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              handleRateChangeWithDays(e, powerExesos2, setRateExesos2, setResultExesos2, 'Exesos2Rate')
                            } />€/kW día</a>
                        </Form.Item>
                      </>
                      : ''}
                </Col>
                <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                  {showExcessButtons && (selectedOptionExcess === 2 || selectedOptionExcess === 3) && (
                    <a className='nobold'>{resultExesos2 !== null ? `${resultExesos2}€` : '___€'}</a>
                  )}
                </Col>
              </Row>
            )}
            {showExcessButtons && selectedOptionExcess === 3 && (
              <Row className='verde'>
                <Col xs={0} lg={8} xl={8} className='contentleft paddtextsoleft'>

                </Col>
                <Col xs={18} lg={10} xl={10} className='contentleft'>
                  <Form.Item
                    name="ExesosPower3"
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  ><a className='nobold paddingmiddletoright'>
                      P : {" "}
                      <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handlePowerChangeWithDays(e, 'Exesos3Power')}   /> kW x {differenceInDays} días x &nbsp; </a>
                  </Form.Item>
                  <Form.Item
                    name="ExesosRate3"
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  ><a className='nobold '>
                      <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        handleRateChangeWithDays(e, powerExesos3, setRateExesos3, setResultExesos3, 'Exesos3Rate')
                      } />€/kW día</a>
                  </Form.Item>
                </Col>
                <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                  <a className='nobold'>{resultExesos3 !== null ? `${resultExesos3}€` : '___€'}</a>
                </Col>
              </Row>
            )}
            {showExcessButtons && (
              <Row className='verde'>
                <Col span={8} className='contentleft paddtextsoleft'>
                  <Button className={`roundedbutton smaller-button marginnumberbuttons ${selectedOptionExcess === 1 ? 'selected' : ''}`} onClick={() => handleOptionButtonClickExcess(1)}>1</Button>
                  <Button className={`roundedbutton smaller-button marginnumberbuttons ${selectedOptionExcess === 2 ? 'selected' : ''}`} onClick={() => handleOptionButtonClickExcess(2)}>2</Button>
                  <Button className={`roundedbutton smaller-button marginnumberbuttons ${selectedOptionExcess === 3 ? 'selected' : ''}`} onClick={() => handleOptionButtonClickExcess(3)}>3</Button>
                  <Button className={`roundedbutton smaller-button somarginnumberbuttons`} onClick={handleLessButtonClickExcess}>-</Button>
                </Col>
              </Row>
            )}


            <Row className='blanco'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tienereactive} visible={messageReactive} placement="bottom">
                  <a className='negrita paddtextleft'>Energía Reactiva   </a>
                  <Button className={`roundedbutton smaller-button`} onClick={handlePlusButtonClickReactive}> + </Button>
                </Popover>
                {showReactive && (
                  <Button className={`roundedbutton smaller-button somarginnumberbuttons`} onClick={handleLessButtonClickReactive}>-</Button>)}
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                {showReactive
                  ? <>
                    <Form.Item
                      name="ReactivaPower1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    ><a className='nobold paddingmiddletoright'>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                          onChange={(e) =>
                            handlePowerChange(e, setPowerReactiva1, rateReactiva1, setResultReactiva1, 'Reactiva1Power')
                          } /> kVArh x &nbsp;</a>
                    </Form.Item>
                    <Form.Item
                      name="ReactivaRate1"
                      className='height'
                      rules={[
                        { required: true, message: '' },
                      ]}
                    >
                      <a className='nobold '>
                        <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                          onChange={(e) =>
                            handleRateChange(e, powerReactiva1, setRateReactiva1, setResultReactiva1, 'Reactiva1Rate')
                          } />€/kVArh</a>
                    </Form.Item>
                  </>
                  : ''}
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                {showReactive && (
                  <a className='nobold'>{resultReactiva1 !== null ? `${resultReactiva1}€` : '___€'}</a>
                )}
                <Popover content={reactivacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Finanaciación bono social fijo</a>
              </Col>
              <Col xs={17} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'> {differenceInDays} días x 0,038455 €/día</a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{(differenceInDays * 0.038455).toFixed(2)}€</a>
                <Popover content={bonosocialcontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Impuesto sobre electricidad</a>
              </Col>
              <Col xs={17} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'>2,5% {calculateTotal() !== null ? `s/${calculateTotal()} €` : '__'} </a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{calculateTotal() !== null ? `${(calculateTotal() * 0.025).toFixed(2)} €` : '__€'}</a>
                <Popover content={impuestocontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='line'>
            </Row>
            <Row className='blanco'>
              <Col xs={18} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft green'>Total Energía</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='green'>{(calculateTotal() + calculateTotal() * 0.025).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <Popover content={tieneother} visible={messageOthers} placement="bottom">
                  <a className='negrita paddtextleft'>Servicios y otros conceptos   </a>
                  <Button
                    className={`roundedbutton smaller-button`}
                    onClick={handleAddServicesAndOthersRow}
                  >
                    +
                  </Button>
                </Popover>
              </Col>
              <Col xs={0} lg={10} xl={10}>
              </Col>
              <Col xs={0} lg={6} xl={6} className='contentright'>
              </Col>
            </Row>
            {ServicesAndOthersRows.map((row) => (
              <Row key={row.rowKey}
                className='verde'
              >
                <Col xs={15} lg={8} xl={8} className='contentleft'>

                  <a className='nobold paddtextleft'>Monto total en Euros: {""} </a>

                </Col>
                <Col xs={0} lg={10} xl={10} className='contentleft'>
                </Col>
                <Col xs={9} lg={6} xl={6} className='contentright'>
                  <Form.Item
                    name={`ServiceAndOthersAmount`}
                    className='height'
                    rules={[
                      { required: true, message: '' },
                    ]}
                  >
                    <a className='nobold paddtextright'><input type='number' className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e) => handleServicesAndOthersInputChange(row.rowKey, e.target.value)}
                    ></input>€ </a>
                  </Form.Item>
                  <Button
                    className={`roundedbutton smaller-button paddtextrightnoquestion`}
                    onClick={() => handleDeleteServicesAndOthersRow(row.rowKey)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}
            <Row className='verde'>
              <Col xs={24} lg={8} xl={8} className='contentleft'>
                <a className='nobold paddtextleft'>Alquiler equipos de medida</a>
              </Col>
              <Col xs={19} lg={10} xl={10} className='contentleft'>
                <Form.Item
                  name="equipodemedidaprice"
                  className='height'
                  rules={[
                    { required: true, message: '' },
                  ]}
                >
                  <a className='nobold paddingmiddletoright '> {differenceInDays} días x &nbsp;
                    <input type="number" className='inputbuttonsize' onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleequipodemedidapriceChange(e)}
                      value={equipodemedidaprice !== undefined ? equipodemedidaprice.toString() : ''}
                    />€/ día</a>
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xl={6} className='contentright'>
                <a className='nobold '>{equipodemedidaprice ? (differenceInDays * equipodemedidaprice).toFixed(2) + '€' : '0€'}</a>
                <Popover content={alquilerquiposcontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            <Row className='line'>
            </Row>
            <Row className='blanco'>
              <Col xs={13} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft green'>Total Servicios y Otros Conceptos</a>
              </Col>
              <Col xs={5} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className=' green'>{(calculateTotalOthers() + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='verde'>
              <Col xs={18} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft '>Importe Total</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className=' '>{(calculateTotal() + calculateTotalOthers() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')).toFixed(2)}€</a>
              </Col>
            </Row>
            <Row className='blanco'>
              <Col xs={10} lg={8} xl={8} className='contentleft'>
                <a className=' paddtextleft '>IVA Electricidad</a>
              </Col>
              <Col xs={7} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'> {ivaValue} % s/{(calculateTotal() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')).toFixed(2)}</a>
              </Col>
              <Col xs={7} lg={6} xl={6} className='contentright'>
                <a className=' '>{((calculateTotal() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) * (ivaValue / 100)).toFixed(2)}€</a>
                <Popover content={ivacontent} trigger="hover">
                  <QuestionCircleFilled className='question questioncomer paddtextright' />
                </Popover>
              </Col>
            </Row>
            {ServicesAndOthersRows.length > 0 && (
              <Row className='blanco'>
                <Col span={8} className='contentleft'>
                  <a className=' paddtextleft '>IVA Servicios</a>
                </Col>
                <Col span={10} className='contentleft'>
                  <a className='nobold paddingmiddletoright'> 21 % s/{calculateTotalOthers().toFixed(2)}</a>
                </Col>
                <Col span={6} className='contentright'>
                  <a className=' '>{((calculateTotalOthers()) * (21 / 100)).toFixed(2)}€</a>
                  <Popover content={ivacontent} trigger="hover">
                    <QuestionCircleFilled className='question questioncomer paddtextright' />
                  </Popover>
                </Col>
              </Row>
            )}
            <Row className='line'>
            </Row>
            <Row className='verde'>
              <Col xs={18} lg={8} xl={8} className='contentleft'>
                <a className='green paddtextleft '>Total Importe Factura</a>
              </Col>
              <Col xs={0} lg={10} xl={10} className='contentleft'>
                <a className='nobold paddingmiddletoright'></a>
              </Col>
              <Col xs={3} lg={6} xl={6} className='contentright paddtextrightnoquestion2'>
                <a className='green'>{((calculateTotal() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) + ((calculateTotal() + calculateTotal() * 0.025 + Number(equipodemedidaprice ? (differenceInDays * equipodemedidaprice) : '0')) * (ivaValue / 100)) + (calculateTotalOthers() + (calculateTotalOthers()) * (21 / 100))).toFixed(2)}€</a>
              </Col>
            </Row>
          </Col>
          <Row className='contenttocenter'>
            <Button type="primary" shape="round" className="buttoncont paddbutton" htmlType="submit" onClick={handleFormSubmit} >
              Continuar
            </Button>
          </Row>
          <Row className='contenttocenter'>
            <a className='greybutton paddtexttop' target="_blank" href='https://api.whatsapp.com/send/?phone=34655794576&text=&text=%C2%A1Hola%20ElectryConsulting!%20necesito%20ayuda'>¿ No coincide con tu factura ?</a>
          </Row>
          <Modal
            title="Error en la validacion del formulario"
            open={errorModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalOk}
            footer={[
              <Button
                className='modalbutton'
                onClick={handleModalOk}
              >
                Continuar
              </Button>,
            ]}
          >
            <p>Complete el formulario y de manera correcta.</p>
          </Modal>
        </Card>
      </Form>
    </Container>
  );
}

export default CargarDatos3o6TD;