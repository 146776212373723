import React from 'react';
import '../assets/styles/NoRecommendationComponent.css';
import {Col, Row, Result, Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const NoRecommendationComponent = () => {
  const navigate = useNavigate();

  const handleBackToMenu = () => {
    navigate('/');
  };
 
  return (
    <div className="no-recommendation-container">
      <Row>
        <Col className='text-container'>
          <Result status="500" className='image500'/>
          <h2>Ooops... Lo Sentimos</h2>
          <p>Estamos relizando un estudio detallado, necesitaremos contactarlo para brindarle el mejor servicio posible.</p>
          <Button type="primary" onClick={handleBackToMenu}>
            Volver al Inicio
          </Button>
          <Button className='modalbuttonwpp separar pointer' target="_blank" href='https://api.whatsapp.com/send/?phone=34655794576&text=&text=%C2%A1Hola%20ElectryConsulting!%20necesito%20ayuda'>
            Contactar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NoRecommendationComponent;