const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const getProductById = async (productId: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/products/${productId}`)
    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }

    return await response.json()
  } catch (err) {
    console.error('Error fetching product:', err);
    return null;
  }
}

export const getProducts = async () => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/products`, {credentials: "include"})
    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }
    return await response.json()
  } catch (err) {
    console.error('Error fetching product:', err);
    return null;
  }
}

export const deleteProduct = async (id: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/products/delete/${id}`, {
          method: 'DELETE',
          credentials: "include"
      });
      if (!response.ok) {
          throw new Error('Failed to delete user');
      }
      console.log('User deleted successfully');
      return true;
  } catch (error) {
      console.error('Error deleting user:', error);
      return false;
  }
};