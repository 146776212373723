import React from "react";
import {Document, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';

const image = require('../assets/img/ComparativaE.png')
const presentacion = require('../assets/img/Presentacion.png')

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
  },
  image: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  blackText: {
    fontSize: 9,
    fontWeight: 'normal',
    color: 'black',
  },
  whiteText: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'white',
  },
  nombre: {
    position: 'absolute',
    top: 87,
    left: 125,
  },
  servicio: {
    position: 'absolute',
    top: 107,
    left: 125,
  },
  tipoServicio: {
    position: 'absolute',
    top: 127,
    left: 125,
  },
  cups: {
    position: 'absolute',
    top: 148,
    left: 125,
  },
  ahorroCantidad: {
    position: 'absolute',
    top: 148,
    left: 380,
  },
  ahorroPorcentaje: {
    position: 'absolute',
    top: 148,
    left: 495,
  },
  excesosPotenciaActual: {
    position: 'absolute',
    top: 589,
    left: 200,
  },

  excesosPotenciaProducto: {
    position: 'absolute',
    top: 589,
    left: 505,
  },
  energiaReactivaActual: {
    position: 'absolute',
    top: 610,
    left: 200,
  },
  energiaReactivaProducto: {
    position: 'absolute',
    top: 610,
    left: 505,
  },
  otrosActual: {
    position: 'absolute',
    top: 631,
    left: 200,
  },
  otrosProducto: {
    position: 'absolute',
    top: 631,
    left: 505,
  },
  contadorActual: {
    position: 'absolute',
    top: 651,
    left: 200,
  },
  contadorProducto: {
    position: 'absolute',
    top: 651,
    left: 505,
  },
  impuestoActual: {
    position: 'absolute',
    top: 672,
    left: 200,
  },
  impuestoProducto: {
    position: 'absolute',
    top: 672,
    left: 505,
  },
  ivaActual: {
    position: 'absolute',
    top: 692,
    left: 200,
  },
  ivaProducto: {
    position: 'absolute',
    top: 692,
    left: 505,
  },
  totalActual: {
    position: 'absolute',
    top: 712,
    left: 195,
  },
  totalProducto: {
    position: 'absolute',
    top: 712,
    left: 500,
  },
  P1TypeActualPower: {
    position: 'absolute',
    top: 408,
    left: 42,
  },
  P2TypeActualPower: {
    position: 'absolute',
    top: 428,
    left: 42,
  },
  P3TypeActualPower: {
    position: 'absolute',
    top: 449,
    left: 42,
  },
  P4TypeActualPower: {
    position: 'absolute',
    top: 470,
    left: 42,
  },
  P5TypeActualPower: {
    position: 'absolute',
    top: 490,
    left: 42,
  },
  P6TypeActualPower: {
    position: 'absolute',
    top: 510,
    left: 42,
  },
  P1ValueActualPower: {
    position: 'absolute',
    top: 408,
    left: 87,
  },
  P2ValueActualPower: {
    position: 'absolute',
    top: 428,
    left: 87,
  },
  P3ValueActualPower: {
    position: 'absolute',
    top: 449,
    left: 87,
  },
  P4ValueActualPower: {
    position: 'absolute',
    top: 470,
    left: 87,
  },
  P5ValueActualPower: {
    position: 'absolute',
    top: 490,
    left: 87,
  },
  P6ValueActualPower: {
    position: 'absolute',
    top: 510,
    left: 87,
  },
  P1UnitPriceActualPower: {
    position: 'absolute',
    top: 408,
    left: 150,
  },
  P2UnitPriceActualPower: {
    position: 'absolute',
    top: 428,
    left: 150,
  },
  P3UnitPriceActualPower: {
    position: 'absolute',
    top: 449,
    left: 150,
  },
  P4UnitPriceActualPower: {
    position: 'absolute',
    top: 470,
    left: 150,
  },
  P5UnitPriceActualPower: {
    position: 'absolute',
    top: 490,
    left: 150,
  },
  P6UnitPriceActualPower: {
    position: 'absolute',
    top: 510,
    left: 150,
  },
  P1SubtotalActualPower: {
    position: 'absolute',
    top: 408,
    left: 215,
  },
  P2SubtotalActualPower: {
    position: 'absolute',
    top: 428,
    left: 215,
  },
  P3SubtotalActualPower: {
    position: 'absolute',
    top: 449,
    left: 215,
  },
  P4SubtotalActualPower: {
    position: 'absolute',
    top: 470,
    left: 215,
  },
  P5SubtotalActualPower: {
    position: 'absolute',
    top: 490,
    left: 215,
  },
  P6SubtotalActualPower: {
    position: 'absolute',
    top: 510,
    left: 215,
  },
  P1TypeProductPower: {
    position: 'absolute',
    top: 408,
    left: 346,
  },
  P2TypeProductPower: {
    position: 'absolute',
    top: 428,
    left: 346,
  },
  P3TypeProductPower: {
    position: 'absolute',
    top: 449,
    left: 346,
  },
  P4TypeProductPower: {
    position: 'absolute',
    top: 470,
    left: 346,
  },
  P5TypeProductPower: {
    position: 'absolute',
    top: 490,
    left: 346,
  },
  P6TypeProductPower: {
    position: 'absolute',
    top: 510,
    left: 346,
  },
  P1ValueProductPower: {
    position: 'absolute',
    top: 408,
    left: 391,
  },
  P2ValueProductPower: {
    position: 'absolute',
    top: 428,
    left: 391,
  },
  P3ValueProductPower: {
    position: 'absolute',
    top: 449,
    left: 391,
  },
  P4ValueProductPower: {
    position: 'absolute',
    top: 470,
    left: 391,
  },
  P5ValueProductPower: {
    position: 'absolute',
    top: 490,
    left: 391,
  },
  P6ValueProductPower: {
    position: 'absolute',
    top: 510,
    left: 391,
  },
  P1UnitPriceProductPower: {
    position: 'absolute',
    top: 408,
    left: 454,
  },
  P2UnitPriceProductPower: {
    position: 'absolute',
    top: 428,
    left: 454,
  },
  P3UnitPriceProductPower: {
    position: 'absolute',
    top: 449,
    left: 454,
  },
  P4UnitPriceProductPower: {
    position: 'absolute',
    top: 470,
    left: 454,
  },
  P5UnitPriceProductPower: {
    position: 'absolute',
    top: 490,
    left: 454,
  },
  P6UnitPriceProductPower: {
    position: 'absolute',
    top: 510,
    left: 454,
  },
  P1SubtotalProductPower: {
    position: 'absolute',
    top: 408,
    left: 519,
  },
  P2SubtotalProductPower: {
    position: 'absolute',
    top: 428,
    left: 519,
  },
  P3SubtotalProductPower: {
    position: 'absolute',
    top: 449,
    left: 519,
  },
  P4SubtotalProductPower: {
    position: 'absolute',
    top: 470,
    left: 519,
  },
  P5SubtotalProductPower: {
    position: 'absolute',
    top: 490,
    left: 519,
  },
  P6SubtotalProductPower: {
    position: 'absolute',
    top: 510,
    left: 519,
  },
  '0consumedEnergyProduct': {
    position: 'absolute',
    top: 268,
    left: 346
  },
  '1consumedEnergyProduct': {
    position: 'absolute',
    top: 288,
    left: 346
  },
  '2consumedEnergyProduct': {
    position: 'absolute',
    top: 308,
    left: 346
  },
  '0consumedEnergyValueProduct': {
    position: 'absolute',
    top: 268,
    left: 391
  },
  '1consumedEnergyValueProduct': {
    position: 'absolute',
    top: 288,
    left: 391
  },
  '2consumedEnergyValueProduct': {
    position: 'absolute',
    top: 308,
    left: 391
  },
  '0UnitPriceProductPower': {
    position: 'absolute',
    top: 268,
    left: 454
  },
  '1UnitPriceProductPower': {
    position: 'absolute',
    top: 288,
    left: 454
  },
  '2UnitPriceProductPower': {
    position: 'absolute',
    top: 308,
    left: 454
  },
  '0SubtotalProductPower': {
    position: 'absolute',
    top: 268,
    left: 514
  },
  '1SubtotalProductPower': {
    position: 'absolute',
    top: 288,
    left: 514
  },
  '2SubtotalProductPower': {
    position: 'absolute',
    top: 308,
    left: 514
  },
  '0consumedEnergy': {
    position: 'absolute',
    top: 268,
    left: 41
  },
  '1consumedEnergy': {
    position: 'absolute',
    top: 288,
    left: 41
  },
  '2consumedEnergy': {
    position: 'absolute',
    top: 308,
    left: 41
  },
  '0consumedEnergyValue': {
    position: 'absolute',
    top: 268,
    left: 86
  },
  '1consumedEnergyValue': {
    position: 'absolute',
    top: 288,
    left: 86
  },
  '2consumedEnergyValue': {
    position: 'absolute',
    top: 308,
    left: 86
  },
  '0UnitPricePower': {
    position: 'absolute',
    top: 268,
    left: 149
  },
  '1UnitPricePower': {
    position: 'absolute',
    top: 288,
    left: 149
  },
  '2UnitPricePower': {
    position: 'absolute',
    top: 308,
    left: 149
  },
  '0SubtotalPower': {
    position: 'absolute',
    top: 268,
    left: 209
  },
  '1SubtotalPower': {
    position: 'absolute',
    top: 288,
    left: 209
  },
  '2SubtotalPower': {
    position: 'absolute',
    top: 308,
    left: 209
  },
  totalEnergyBill: {
    position: 'absolute',
    top: 329,
    left: 175
  },
  totalEnergyProduct: {
    position: 'absolute',
    top: 329,
    left: 480
  },
  totalPowerBill: {
    position: 'absolute',
    top: 530,
    left: 175
  },
  totalPowerProduct: {
    position: 'absolute',
    top: 530,
    left: 480
  },
  welcomeView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  welcome: {
    position: 'absolute',
    top: 360,
    fontSize: 25,
    fontWeight: 'bold'
  },
  savingsView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  savings: {
    position: 'absolute',
    top: 25,
    fontSize: 30,
  }
});


function ElectricityPDF({recommendationData}: {
  recommendationData: any,
}) {

  return (
    <Document>
      <Page>
        <Image src={presentacion} style={styles.image}></Image>
        <View style={styles.welcomeView}>
          <Text style={styles.welcome}>
            {`Hola ${recommendationData?.client}`}
          </Text>
        </View>
        <View style={styles.savingsView}>
          <Text style={styles.savings}>
            {recommendationData?.savings}
          </Text>
        </View>
      </Page>
      <Page>
        <Image src={image} style={styles.image}/>
        <View style={styles.nombre}>
          <Text
            style={styles.blackText}>{recommendationData?.client.length > 20 ? `${recommendationData?.client.substring(0, 20)}...` : recommendationData?.client}</Text>
        </View>

        <View style={styles.servicio}>
          <Text
            style={styles.blackText}>{recommendationData?.service === 'Electricidad' ? 'Electricidad' : 'Gas'}</Text>
        </View>

        <View style={styles.tipoServicio}>
          <Text style={styles.blackText}>{recommendationData?.service_type}</Text>
        </View>

        {recommendationData?.power.details.map((billed_power: any) => (
          <View style={styles[`${billed_power.type}TypeActualPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{billed_power.type}</Text>
          </View>
        ))}
        {recommendationData?.power.details.map((billed_power: any) => (
          <View style={styles[`${billed_power.type}ValueActualPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{billed_power.quantity}</Text>
          </View>
        ))}
        {recommendationData?.power.details.map((billed_power: any) => (
          <View style={styles[`${billed_power.type}UnitPriceActualPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{billed_power.day_price_bill}</Text>
          </View>
        ))}
        {recommendationData?.power.details.map((billed_power: any) => (
          <View style={styles[`${billed_power.type}SubtotalActualPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{billed_power.bill_projection}</Text>
          </View>
        ))}

        {recommendationData?.power.details.map((billed_power: any) => (
          <View style={styles[`${billed_power.type}TypeProductPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{billed_power.type}</Text>
          </View>
        ))}
        {recommendationData?.power.details.map((billed_power: any) => (
          <View style={styles[`${billed_power.type}ValueProductPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{billed_power.quantity}</Text>
          </View>
        ))}
        {recommendationData?.power.details.map((p: any) => (
          <View style={styles[`${p.type}UnitPriceProductPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{p.day_price_product}</Text>
          </View>
        ))}
        {recommendationData?.power.details.map((p: any) => (
          <View style={styles[`${p.type}SubtotalProductPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>{p.product_projection}</Text>
          </View>
        ))}


        {recommendationData?.energy?.details
          .filter((cp: any) => cp.type !== 'Impuesto sobre electricidad')
          .map((cpp: any, i: any) => (
            <View style={styles[`${i}consumedEnergyProduct` as keyof typeof styles]}>
              <Text style={styles.blackText}>
                {cpp.type}
              </Text>
            </View>
          ))}

        {recommendationData?.energy?.details
          .filter((cp: any) => cp.type !== 'Impuesto sobre electricidad')
          .map((cpp: any, i: any) => (
            <View style={styles[`${i}consumedEnergyValueProduct` as keyof typeof styles]}>
              <Text style={styles.blackText}>
                {cpp.quantity}
              </Text>
            </View>
          ))}

        {recommendationData?.energy?.details.map((cp: any, i: any) =>
          <View style={styles[`${i}UnitPriceProductPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>
              {cp.product_price}
            </Text>
          </View>)}

        {recommendationData?.energy.details
          .filter((cp: any) => cp.type !== 'Impuesto sobre electricidad')
          .map((e: any, i: any) => (
            <View style={styles[`${i}SubtotalProductPower` as keyof typeof styles]}>
              <Text style={styles.blackText}>
                {e.product_projection}
              </Text>
            </View>
          ))}

        {recommendationData?.energy?.details
          .filter((cp: any) => cp.type !== 'Impuesto sobre electricidad')
          .map((cpp: any, i: any) => (
            <View style={styles[`${i}consumedEnergy` as keyof typeof styles]}>
              <Text style={styles.blackText}>
                {cpp.type}
              </Text>
            </View>
          ))}

        {recommendationData?.energy?.details
          .filter((cp: any) => cp.type !== 'Impuesto sobre electricidad')
          .map((cpp: any, i: any) => (
            <View style={styles[`${i}consumedEnergyValue` as keyof typeof styles]}>
              <Text style={styles.blackText}>
                {cpp.quantity}
              </Text>
            </View>
          ))}

        {recommendationData?.energy?.details
          .filter((cp: any) => cp.type !== 'Impuesto sobre electricidad')
          .map((cpp: any, i: any) => (
            <View style={styles[`${i}UnitPricePower` as keyof typeof styles]}>
              <Text style={styles.blackText}>
                {cpp.bill_price}
              </Text>
            </View>
          ))}

        {recommendationData?.energy?.details.map((e: any, i: any) => (
          <View style={styles[`${i}SubtotalPower` as keyof typeof styles]}>
            <Text style={styles.blackText}>
              {e.bill_projection}
            </Text>
          </View>
        ))}

        <View style={styles.cups}>
          <Text style={styles.blackText}>{recommendationData?.cups}</Text>
        </View>

        <View style={styles.ahorroCantidad}>
          <Text style={styles.whiteText}>{recommendationData?.savings}</Text>
        </View>

        <View style={styles.ahorroPorcentaje}>
          <Text style={styles.whiteText}>{recommendationData?.savings_pct}</Text>
        </View>

        <View style={styles.excesosPotenciaActual}>
          <Text style={styles.blackText}>{recommendationData?.exceded_power}</Text>
        </View>
        <View style={styles.excesosPotenciaProducto}>
          <Text style={styles.blackText}>{recommendationData?.exceded_power}</Text>
        </View>

        <View style={styles.energiaReactivaActual}>
          <Text style={styles.blackText}>{recommendationData?.reactive_power}</Text>
        </View>
        <View style={styles.energiaReactivaProducto}>
          <Text style={styles.blackText}>{recommendationData?.reactive_power}</Text>
        </View>

        <View style={styles.otrosActual}>
          <Text style={styles.blackText}>{recommendationData?.others}</Text>
        </View>
        <View style={styles.otrosProducto}>
          <Text style={styles.blackText}>{recommendationData?.others}</Text>
        </View>

        <View style={styles.contadorActual}>
          <Text style={styles.blackText}>{recommendationData?.equipment}</Text>
        </View>
        <View style={styles.contadorProducto}>
          <Text style={styles.blackText}>{recommendationData?.equipment}</Text>
        </View>

        <View style={styles.impuestoActual}>
          <Text
            style={styles.blackText}>{recommendationData?.electric_tax.bill}</Text>
        </View>
        <View style={styles.impuestoProducto}>
          <Text style={styles.blackText}>{recommendationData?.electric_tax.product}</Text>
        </View>

        <View style={styles.ivaActual}>
          <Text style={styles.blackText}>{recommendationData?.iva.bill}</Text>
        </View>
        <View style={styles.ivaProducto}>
          <Text style={styles.blackText}>{recommendationData?.iva.product}</Text>
        </View>

        <View style={styles.totalActual}>
          <Text style={styles.whiteText}>{recommendationData?.total.bill}</Text>
        </View>
        <View style={styles.totalProducto}>
          <Text style={styles.whiteText}>{recommendationData?.total.product}</Text>
        </View>

        <View style={styles.totalEnergyBill}>
          <Text style={styles.blackText}>{recommendationData?.energy.total_bill}</Text>
        </View>
        <View style={styles.totalEnergyProduct}>
          <Text style={styles.blackText}>{recommendationData?.energy.total_product}</Text>
        </View>

        <View style={styles.totalPowerBill}>
          <Text style={styles.blackText}>{recommendationData?.power.total_bill}</Text>
        </View>
        <View style={styles.totalPowerProduct}>
          <Text style={styles.blackText}>{recommendationData?.power.total_product}</Text>
        </View>


      </Page>
    </Document>
  );
}

export default ElectricityPDF;