import '../assets/styles/BackImg.css'
import Container from 'react-bootstrap/Container';
import NavbarComp from './NavbarComp';


function BackWithImage(): JSX.Element {
    return (
            <Container className="container-wrapper">
                <Container className="backgroundimage">
                    <NavbarComp/>
                    <div className="top-background"></div>  
                </Container>
            </Container> 
    );
}
export default BackWithImage;