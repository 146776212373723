import '../assets/styles/ContratoDatosResidencial.css';
import { Card, Button} from 'antd';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Agradecimiento(): JSX.Element {
    const navigate = useNavigate();

    const toMenu = () => {
    navigate('/')
    }

    return (
        <Container>
                <Card className="card-residen-contrato" bordered={true}>
                    <h1>Muchas Gracias</h1>
                    <h5 className='subtitle'>En breves será contactado por un asesor</h5>

                    <Button type="primary" shape="round" className="buttoncont" onClick={toMenu}> Volver al inicio </Button>
                </Card>
        </Container>
    );
}

export default Agradecimiento;
