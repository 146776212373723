import '../assets/styles/Navbar.css'
import { Col, Row, message } from 'antd';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/img/logoNavbar.png'
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router-dom';

function NavbarComp(): JSX.Element {
  const cookies = new Cookies();
  const userData = cookies.get('access-token');
  const isLoggedIn = !!userData;
 const navigate = useNavigate();

  const cerrarSesion = () => {
    const expirationTime = new Date(0);
    cookies.set("access-token", '', {
      expires: expirationTime
    })
    message.success('Se cerró la sesión correctamente');
    window.location.reload();
  };

  const handleMenu = () => {
    navigate('/servicioaconsultar')
  };

  return (
    <Navbar className="bg-body-tertiary navbar" expand="md">
      <Col >
        <Link to="/">
          <img
            alt=""
            src={logo}
            width="280"
            height="61"
            className="d-inline-block align-top logo-container"
          />
        </Link>
      </Col>
      {isLoggedIn && (
        <Row className="navbar">
        <Col className="title-col">
          <a className="titlee" onClick={handleMenu}>
            Menú
          </a>
        </Col>
        <Col className="title-col">
          <a className="titlee" onClick={cerrarSesion}>
            Cerrar sesión
          </a>
        </Col>
      </Row>

      )}
    </Navbar>
  );
}
export default NavbarComp;