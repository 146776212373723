import React from "react";
import {Document, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';

const image = require('../assets/img/ComparativaG.png')
const presentacion = require('../assets/img/Presentacion.png')

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
  },
  image: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  blackText: {
    fontSize: 9,
    fontWeight: 'normal',
    color: 'black',
  },
  whiteText: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'white',
  },
  nombre: {
    position: 'absolute',
    top: 87,
    left: 125,
  },
  servicio: {
    position: 'absolute',
    top: 107,
    left: 125,
  },
  tipoServicio: {
    position: 'absolute',
    top: 127,
    left: 125,
  },
  cups: {
    position: 'absolute',
    top: 148,
    left: 125,
  },
  ahorroCantidad: {
    position: 'absolute',
    top: 147,
    left: 380,
  },
  ahorroPorcentaje: {
    position: 'absolute',
    top: 147,
    left: 495,
  },
  welcomeView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  welcome: {
    position: 'absolute',
    top: 360,
    fontSize: 25,
    fontWeight: 'bold'
  },
  savingsView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  savings: {
    position: 'absolute',
    top: 25,
    fontSize: 30,
  },
  periodoPowerFactura: {
    position: 'absolute',
    top: 267,
    left: 150
  },
  precioPowerFactura: {
    position: 'absolute',
    top: 267,
    left: 62
  },
  subtotalPowerFactura: {
    position: 'absolute',
    top: 267,
    left: 205
  },
  totalPowerFactura: {
    position: 'absolute',
    top: 288,
    left: 180
  },
  gasFactura: {
    position: 'absolute',
    top: 398,
    left: 70
  },
  gasPrecioFactura: {
    position: 'absolute',
    top: 398,
    left: 136
  },
  subtotalGasFactura: {
    position: 'absolute',
    top: 398,
    left: 205
  },
  totalGasFactura: {
    position: 'absolute',
    top: 418,
    left: 180
  },
  periodoPowerProducto: {
    position: 'absolute',
    top: 267,
    left: 455
  },
  precioPowerProducto: {
    position: 'absolute',
    top: 267,
    left: 367
  },
  subtotalPowerProducto: {
    position: 'absolute',
    top: 267,
    left: 507
  },
  totalPowerProducto: {
    position: 'absolute',
    top: 288,
    left: 480
  },
  gasProducto: {
    position: 'absolute',
    top: 398,
    left: 374
  },
  gasPrecioProducto: {
    position: 'absolute',
    top: 398,
    left: 440
  },
  subtotalGasProducto: {
    position: 'absolute',
    top: 398,
    left: 505
  },
  totalGasProducto: {
    position: 'absolute',
    top: 418,
    left: 480
  },
  equipmentProducto: {
    position: 'absolute',
    top: 518,
    left: 500
  },
  equipmentFactura: {
    position: 'absolute',
    top: 518,
    left: 190
  },
  gasTaxProducto: {
    position: 'absolute',
    top: 539,
    left: 500
  },
  gasTaxFactura: {
    position: 'absolute',
    top: 539,
    left: 190
  },
  ivaProducto: {
    position: 'absolute',
    top: 560,
    left: 500
  },
  ivaFactura: {
    position: 'absolute',
    top: 560,
    left: 190
  },
  totalProducto: {
    position: 'absolute',
    top: 579,
    left: 500
  },
  totalFactura: {
    position: 'absolute',
    top: 579,
    left: 190
  },

});

function GasPDF({recommendationData}: {
  recommendationData: any
}) {

  return (
    <Document>
      <Page>
        <Image src={presentacion} style={styles.image}></Image>
        <View style={styles.welcomeView}>
          <Text style={styles.welcome}>
            {`Hola ${recommendationData?.client}`}
          </Text>
        </View>
        <View style={styles.savingsView}>
          <Text style={styles.savings}>
            {recommendationData?.savings}
          </Text>
        </View>
      </Page>
      <Page>
        <Image src={image} style={styles.image}/>
        <View style={styles.nombre}>
          <Text
            style={styles.blackText}>{(recommendationData.client ?? "-")?.toString().length > 20
            ? `${(recommendationData.client ?? "-")?.toString().substring(0, 20)}...`
            : (recommendationData.client ?? "-")}</Text>
        </View>
        <View style={styles.servicio}>
          <Text
            style={styles.blackText}>{recommendationData?.service === 'Electricidad' ? 'Electricidad' : 'Gas'}</Text>
        </View>

        <View style={styles.tipoServicio}>
          <Text style={styles.blackText}>{recommendationData?.service_type}</Text>
        </View>

        <View style={styles.cups}>
          <Text style={styles.blackText}>{recommendationData?.cups}</Text>
        </View>

        <View style={styles.ahorroCantidad}>
          <Text style={styles.whiteText}>{recommendationData?.savings}</Text>
        </View>

        <View style={styles.ahorroPorcentaje}>
          <Text style={styles.whiteText}>{recommendationData?.savings_pct}</Text>
        </View>

        {recommendationData.power.details.map((cp: any) => (
          <View style={styles.periodoPowerFactura}>
            <Text style={styles.blackText}>
              {cp.quantity}
            </Text>
          </View>
        ))}
        {recommendationData.power.details.map((cp: any) => (
          <View style={styles.precioPowerFactura}>
            <Text style={styles.blackText}>
              {cp.bill_price}
            </Text>
          </View>
        ))}
        {recommendationData.power.details.map((cp: any) =>(
          <View style={styles.subtotalPowerFactura}>
            <Text style={styles.blackText}>
              {cp.bill_projection}
            </Text>
          </View>
        ))}
        {recommendationData.power.details.map((cp: any) =>(
          <View style={styles.totalPowerFactura}>
            <Text style={styles.blackText}>
              {cp.bill_projection}
            </Text>
          </View>
        ))}

        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.gasFactura}>
            <Text style={styles.blackText}>
              {cp.quantity}
            </Text>
          </View>
        ))}
        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.gasPrecioFactura}>
            <Text style={styles.blackText}>
              {cp.bill_price}
            </Text>
          </View>
        ))}
        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.subtotalGasFactura}>
            <Text style={styles.blackText}>
              {cp.bill_projection}
            </Text>
          </View>
        ))}
        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.totalGasFactura}>
            <Text style={styles.blackText}>
              {cp.bill_projection}
            </Text>
          </View>
        ))}

        {recommendationData.power.details.map((cp: any) => (
          <View style={styles.periodoPowerProducto}>
            <Text style={styles.blackText}>
              {cp.quantity}
            </Text>
          </View>
        ))}
        {recommendationData.power.details.map((cp: any) => (
          <View style={styles.precioPowerProducto}>
            <Text style={styles.blackText}>
              {cp.product_price}
            </Text>
          </View>
        ))}
        {recommendationData.power.details.map((cp: any) => (
          <View style={styles.subtotalPowerProducto}>
            <Text style={styles.blackText}>
              {cp.product_projection}
            </Text>
          </View>
        ))}
        {recommendationData.power.details.map((cp: any) => (
          <View style={styles.totalPowerProducto}>
            <Text style={styles.blackText}>
              {cp.product_projection}
            </Text>
          </View>
        ))}

        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.gasProducto}>
            <Text style={styles.blackText}>
              {cp.quantity}
            </Text>
          </View>
        ))}
        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.gasPrecioProducto}>
            <Text style={styles.blackText}>
              {cp.product_price}
            </Text>
          </View>
        ))}
        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.subtotalGasProducto}>
            <Text style={styles.blackText}>
              {cp.product_projection}
            </Text>
          </View>
        ))}
        {recommendationData.gas.details.map((cp: any) => (
          <View style={styles.totalGasProducto}>
            <Text style={styles.blackText}>
              {cp.product_projection}
            </Text>
          </View>
        ))}

        <View style={styles.equipmentProducto}>
          <Text style={styles.blackText}>{recommendationData?.equipment}</Text>
        </View>
        <View style={styles.equipmentFactura}>
          <Text style={styles.blackText}>{recommendationData?.equipment}</Text>
        </View>

        <View style={styles.gasTaxProducto}>
          <Text style={styles.blackText}>{recommendationData?.gas_tax.product}</Text>
        </View>
        <View style={styles.gasTaxFactura}>
          <Text style={styles.blackText}>{recommendationData?.gas_tax.bill}</Text>
        </View>

        <View style={styles.ivaProducto}>
          <Text style={styles.blackText}>{recommendationData?.iva.product}</Text>
        </View>
        <View style={styles.ivaFactura}>
          <Text style={styles.blackText}>{recommendationData?.iva.bill}</Text>
        </View>

        <View style={styles.totalProducto}>
          <Text style={styles.whiteText}>{recommendationData?.total.product}</Text>
        </View>
        <View style={styles.totalFactura}>
          <Text style={styles.whiteText}>{recommendationData?.total.bill}</Text>
        </View>

      </Page>
    </Document>
  );
}

export default GasPDF;