import Loading from '../components/Loading';
import { Card } from 'antd';
import { Container } from 'react-bootstrap';
import { getProducts } from '../services/ProductServices';
import { useState, useEffect } from 'react';
import ProductIndividualCard from '../components/ProductIndividualCard';
import { deleteProduct } from '../services/ProductServices';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface Product {
    _id: string;
    marketer: string;
    product_service: string;
    product_name: string;
    product_atr: string;
    fare: string;
    priority: number;
    power: number[];
    energy: number[];
}
function ManageProducts(): JSX.Element {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleDeleteProduct = async (id: string) => {
        try {
            await deleteProduct(id);
            setProducts(products.filter(product => product._id !== id));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleAddProduct = async () => {
        navigate('/products/add')
    };

    const handleBack = () => {
        navigate('/servicioaconsultar');
    };

    useEffect(() => {
        async function fetchProducts() {
            const productsData = await getProducts();
            if (productsData) {
                setProducts(productsData);
                setLoading(false);
            }
        }
        fetchProducts();
    }, []);

    return (
        <Container>
            <Card className="cardtable" bordered={true}>
                <CloseOutlined className='fixed-cross' onClick={handleBack} />
                <a>Gestión de Productos</a>
                {loading && <Loading />}
                {products.map(product => (
                    <ProductIndividualCard key={product._id} product={product} onDelete={handleDeleteProduct} />
                ))}
                <PlusCircleOutlined className='ADD' onClick={handleAddProduct} />
            </Card>
        </Container>
    );
}
export default ManageProducts;