import '../assets/styles/Residential.css';
import { Input, Form, Card, Col, Row } from 'antd';
import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useNavigate } from 'react-router-dom';

function Residential(): JSX.Element {
  const [form] = Form.useForm();
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => {
    setAgreed(e.target.checked);
  };

  const openLinkInNewPage = () => {
    window.open('https://electryconsulting.com/politica-de-privacidad/', '_blank');
  };

  const handleInputChange = (fieldName: string, value: string) => {
    form.setFieldsValue({ [fieldName]: value });

    const formValues = form.getFieldsValue();
    localStorage.setItem('formDataResidential', JSON.stringify(formValues));
    localStorage.setItem('agreementResidential', JSON.stringify(agreed));
  };

  const handleFormSubmit = async () => {
    setFormSubmitted(true);

    try {
      await form.validateFields();
      if (agreed) {
        navigate('/factura');
      } else {
        console.log('Please agree to the terms and conditions.');
      }
    } catch (error) {
      console.log('Please complete all form fields.');
    }
  };


  useEffect(() => {
    const storedFormData = localStorage.getItem('agreementResidential');
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      const { agreement, ...formDataWithoutAgreement } = parsedFormData;

      form.setFieldsValue(formDataWithoutAgreement);
    }
  }, []);

  useEffect(() => {
    const storedFormData = localStorage.getItem('formDataResidential');
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      form.setFieldsValue(parsedFormData);
    }
    form.setFieldsValue({ agreement: agreed });
  }, [form, agreed]);

  return (
    <Container>
      <Form form={form} name="control-hooks">
        <Card className="card-residen" bordered={true}>
          Necesitaremos algunos datos...
          <Row className='alignment'>
            <Col className='col-inputs'>
              <Form.Item
                name="Nombre"
                validateFirst
                rules={[
                  { required: true, message: 'Ingrese su Nombre' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const lettersRegex = /^[A-Za-z]+$/;
                      const minThreeLetters = value.length >= 3;

                      if (!value) {
                        return Promise.reject(new Error('Ingrese su Nombre'));
                      }

                      if (!lettersRegex.test(value) || !minThreeLetters) {
                        return Promise.reject(new Error('Nombre no válido '));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Nombre"
                  className='input'
                  onChange={(e) => handleInputChange('Nombre', e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="Apellido"
                validateFirst
                rules={[
                  { required: true, message: 'Ingrese su Apellido' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const lettersRegex = /^[A-Za-z]+$/;
                      const minThreeLetters = value.length >= 2;

                      if (!value) {
                        return Promise.reject(new Error('Ingrese su Apellido'));
                      }

                      if (!lettersRegex.test(value) || !minThreeLetters) {
                        return Promise.reject(new Error('Apellido no válido'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Apellido"
                  className='input'
                  onChange={(e) => handleInputChange('Apellido', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className='col-inputs'>
              <Form.Item
                name="Teléfono"
                validateFirst
                rules={[
                  { required: true, message: 'Ingrese su Teléfono' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const phoneRegex = /^[0-9]{9}$/;

                      if (!value) {
                        return Promise.reject(new Error('Ingrese su Teléfono'));
                      }

                      if (!phoneRegex.test(value)) {
                        return Promise.reject(new Error('Teléfono no válido'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Teléfono"
                  className='input'
                  type='number'
                  onChange={(e) => handleInputChange('Teléfono', e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="Email"
                validateFirst
                rules={[
                  { required: true, message: 'Ingrese su Email' },
                  {
                    validator: async (_, value) => {
                      if (!formSubmitted) {
                        return Promise.resolve();
                      }

                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                      if (!value) {
                        return Promise.reject(new Error('Ingrese su Email'));
                      }

                      if (!emailRegex.test(value)) {
                        return Promise.reject(new Error('Email no válido'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  className='input'
                  onChange={(e) => handleInputChange('Email', e.target.value)}
                />
              </Form.Item>
            </Col>
            <a className='textinfo'>Vamos a necesitar unos datos técnicos sobre tu factura para poder ofrecerte la mejor oferta</a>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[{ required: true, message: '' }]}
              validateStatus={formSubmitted && !agreed ? 'error' : ''}
              help={formSubmitted && !agreed ? 'Debe aceptar los términos y condiciones.' : ''}
            >
              <Checkbox onChange={onChange}>Acepto los <a className="link"
                onClick={openLinkInNewPage}>términos y condiciones</a></Checkbox>
            </Form.Item>
          </Row>
          <Button type="primary" shape="round" className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
            Continuar
          </Button>
        </Card>
      </Form>
    </Container>
  );
}

export default Residential;
