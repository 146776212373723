import '../assets/styles/BillLoad.css';
import { Form, Card, Col, Row } from 'antd';
import { FilePdfOutlined, AuditOutlined } from '@ant-design/icons';
import { Container } from 'react-bootstrap';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function BillLoad(): JSX.Element {
    const [size, setSize] = useState<SizeType>('middle');
    const [form] = Form.useForm();
    const [selectedLoad, setSelectedLoad] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleLoadClick = (load: string) => {
        setSelectedLoad(load);
        form.setFieldsValue({ carga: load });
        const formValues = form.getFieldsValue();
        localStorage.setItem('TipoDeCarga', JSON.stringify(formValues));
    };

    const handleFormSubmit = () => {
        if (selectedLoad) {
            if (selectedLoad === 'factura') {
                navigate('/factura/cargardocumento');
            } else if (selectedLoad === 'manual') {
                const selectedService = localStorage.getItem('selectedService');
                if (selectedService === 'electricidad' || selectedService === 'gas') {
                    const selectedUser = localStorage.getItem('selectedUsuario');
                    if (selectedUser === 'residencial' || selectedUser === 'comercial') {
                        navigate(`/${selectedUser}/${selectedService}`);
                    } else {
                        console.log('Selected service not found.');
                    }
                } else {
                    console.log('Selected service not found.');
                }
            }
        }
    };

    return (
        <Container>
            <Form form={form} name="control-hooks">
                <Card className="cardconsult" bordered={true}>
                    <p>Sabemos que es difícil comprender las facturas...</p>
                    <p>Así que lo hacemos por ti</p>
                    <Row className='separate-bill'>
                        <Form.Item
                            name="carga"
                            rules={[
                                {
                                    required: true,
                                    message: 'Porfavor seleccione un tipo de carga',
                                },
                            ]}
                        >
                            <Row gutter={16} justify="center" align="middle" className='content-align'>
                                <Col>
                                    <Card
                                        hoverable
                                        className={`selectcards ${selectedLoad === 'factura' ? 'clicked' : ''}`}
                                        onClick={() => handleLoadClick('factura')}
                                    >
                                        <div className='text'>
                                        <FilePdfOutlined />  Factura
                                        </div>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        hoverable
                                        className={`selectcards ${selectedLoad === 'manual' ? 'clicked' : ''}`}
                                        onClick={() => handleLoadClick('manual')}
                                    >
                                        <div className='text'>
                                            <AuditOutlined /> Manual
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Row>
                    <Button type="primary" shape="round" size={size} className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
                        Continuar
                    </Button>
                </Card>
            </Form>
        </Container>
    );
}

export default BillLoad;