const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

const postBill = async (bill: object) => {
    try {
        const response = await fetch(`${REACT_APP_BFF_URL}/api/bills/save-bill`, {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                bill: bill
            })
        });
        if (!response.ok) {
            throw new Error('Failed to save bill');
        }
        const data = await response.json();
        localStorage.setItem('insertedId', data.insertedId);
        return true;
    } catch (error) {
        console.error('Error saving bill:', error);
        return false;
    }
};



export const jsonFormatelec2 = async () => {
    const selectedUsuario = localStorage.getItem('selectedUsuario');
    let suministro, billdata;

    if (selectedUsuario === 'residencial') {
        suministro = localStorage.getItem('formDataElectricidadCarga Residencial');
        billdata = localStorage.getItem('formDataCarga2.0');
    } else if (selectedUsuario === 'comercial') {
        suministro = localStorage.getItem('formDataElectricidadCarga Comercial');
        billdata = localStorage.getItem('formDataCarga2.0');
    }

    if (!suministro || !billdata) {
        console.error("Suministro or billdata is undefined.");
        return null;
    }

    const suministroData = JSON.parse(suministro);
    const billdataData = JSON.parse(billdata);

    const storedInicio = new Date(suministroData.Inicio);
    const storedFin = new Date(suministroData.final);
    const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
    const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    const iniciocorrecto = formatDate(suministroData.Inicio);
    const finalcorrecto = formatDate(suministroData.final);

    const consumed_power = [
        billdataData.consumida1Power ? {
            type: "P1",
            value: parseFloat(billdataData.consumida1Power),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.consumida1Rate),
                currency: "€"
            }
        } : null,
        billdataData.consumida2Power ? {
            type: "P2",
            value: parseFloat(billdataData.consumida2Power),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.consumida2Rate),
                currency: "€"
            }
        } : null,
        billdataData.consumida3Power ? {
            type: "P3",
            value: parseFloat(billdataData.consumida3Power),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.consumida3Rate),
                currency: "€"
            }
        } : null
    ].filter((item): item is { type: string; value: number; unit: string; unit_price: { value: number; currency: string } } => item !== null);

    if (consumed_power.length === 1) {
        consumed_power[0].type = "Termino Fijo";
    }
    const billed_power = [
        billdataData.PuntaPower ? {
            type: "Punta",
            value: parseFloat(billdataData.PuntaPower),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.PuntaRate),
                currency: "€"
            }
        } : null,
        billdataData.VallePower ? {
            type: "Valle",
            value: parseFloat(billdataData.VallePower),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ValleRate),
                currency: "€"
            }
        } : null,
    ].filter(item => item !== null);

    const exceeded_power = [
        billdataData.ExesosPower1 ? {
            type: "P1",
            value: parseFloat(billdataData.ExesosPower1),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ExesosRate1),
                currency: "€"
            }
        } : null,
        billdataData.ExesosPower2 ? {
            type: "P2",
            value: parseFloat(billdataData.ExesosPower2),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ExesosRate2),
                currency: "€"
            }
        } : null,
        billdataData.ExesosPower3 ? {
            type: "P3",
            value: parseFloat(billdataData.ExesosPower3),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ExesosRate3),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const reactive_power = [
        billdataData.ReactivaPower1 ? {
            type: "P",
            value: parseFloat(billdataData.ReactivaPower1),
            unit: `kVArh`,
            unit_price: {
                value: parseFloat(billdataData.ReactivaRate1),
                currency: "€"
            }
        } : null,
    ].filter(item => item !== null);

    const billed_period =
    {
        start: iniciocorrecto,
        end: finalcorrecto
    }


    const discount = [
        billdataData.DiscountAmount ? {
            price: {
                value: parseFloat(billdataData.DiscountAmount),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const other_concepts = [
        billdataData.ServiceAndOthersAmount ? {
            price: {
                value: parseFloat(billdataData.ServiceAndOthersAmount),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const equipment =
    {
        period: differenceInDays,
        unit_price: {
            value: parseFloat(billdataData.equipodemedidaprice),
            currency: "€"
        }
    }

    const supply_address =
    {
        location: `${suministroData.Provincia}, ${suministroData.Población}, ${suministroData.Dirección}`,
        postal_code: suministroData.CódigoPostal
    }

    const atr =
    {
        type: "TD",
        name: "2.0"
    }

    const totalget = localStorage.getItem('total');

    const total =
    {
        price: {
            value: parseFloat(totalget ?? "0"),
            currency: "€"
        }
    }

    const jsonObject = {
        "marketer": '',
        "owner": '',
        "iban": '',
        "supply_address": supply_address,
        "cups": suministroData.CUPS,
        "atr": atr,
        "bill_period": billed_period,
        "consumed_powers": consumed_power,
        "billed_powers": billed_power,
        "reactive_powers": reactive_power,
        "exceeded_powers": exceeded_power,
        "discounts": discount,
        "equipment": equipment,
        "other_concepts": other_concepts,
        "taxes": null,
        "bono": {
            "period": 0,
            "unit_price": {
                "value": 0,
                "currency": "€"
            }
        },
        "total": total,
        "power_description": []
    };
    return postBill(jsonObject);
};

export const jsonFormatelec36 = async () => {
    let suministro, billdata, selectedServiceTypeElectricidad;
    suministro = localStorage.getItem('formDataElectricidadCarga Comercial');
    billdata = localStorage.getItem('formDataCarga3.0/6.1');
    selectedServiceTypeElectricidad = localStorage.getItem('selectedServiceTypeElectricidad');


    if (!suministro || !billdata || !selectedServiceTypeElectricidad) {
        console.error("Suministro or billdata is undefined.");
        return null;
    }

    selectedServiceTypeElectricidad = selectedServiceTypeElectricidad.match(/^\d+\.\d+/)?.[0];

    const suministroData = JSON.parse(suministro);
    const billdataData = JSON.parse(billdata);

    const storedInicio = new Date(suministroData.Inicio);
    const storedFin = new Date(suministroData.final);
    const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
    const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    const month = storedFin.getMonth() + 1;

    let puntaValue = '0';
    let llanoValue = '0';

    switch (month) {
        case 12:
        case 1:
        case 2:
        case 7:
            puntaValue = 'P1';
            llanoValue = 'P2';
            break;
        case 3:
        case 11:
            puntaValue = 'P2';
            llanoValue = 'P3';
            break;
        case 6:
        case 8:
        case 9:
            puntaValue = 'P3';
            llanoValue = 'P4';
            break;
        case 4:
        case 5:
        case 10:
            puntaValue = 'P4';
            llanoValue = 'P5';
            break;
        default:
            break;
    }

    const iniciocorrecto = formatDate(suministroData.Inicio);
    const finalcorrecto = formatDate(suministroData.final);


    const consumed_power = [
        billdataData.consumida1Power ? {
            type: puntaValue,
            value: parseFloat(billdataData.consumida1Power),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.consumida1Rate),
                currency: "€"
            }
        } : null,
        billdataData.consumida2Power ? {
            type: llanoValue,
            value: parseFloat(billdataData.consumida2Power),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.consumida2Rate),
                currency: "€"
            }
        } : null,
        billdataData.consumida3Power ? {
            type: "P6",
            value: parseFloat(billdataData.consumida3Power),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.consumida3Rate),
                currency: "€"
            }
        } : null
    ].filter((item): item is { type: string; value: number; unit: string; unit_price: { value: number; currency: string } } => item !== null);

    if (consumed_power.length === 1) {
        consumed_power[0].type = "Termino Fijo";
    }

    const billed_power = [
        billdataData.P1Power ? {
            type: "P1",
            value: billdataData.P1Power,
            period: differenceInDays,
            unit_price: {
                value: (billdataData.P1Rate),
                currency: "€"
            }
        } : null,
        billdataData.P2Power ? {
            type: "P2",
            value: parseFloat(billdataData.P2Power),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.P2Rate),
                currency: "€"
            }
        } : null,
        billdataData.P3Power ? {
            type: "P3",
            value: parseFloat(billdataData.P3Power),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.P3Rate),
                currency: "€"
            }
        } : null,
        billdataData.P4Power ? {
            type: "P4",
            value: parseFloat(billdataData.P4Power),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.P4Rate),
                currency: "€"
            }
        } : null,
        billdataData.P5Power ? {
            type: "P5",
            value: parseFloat(billdataData.P5Power),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.P5Rate),
                currency: "€"
            }
        } : null,
        billdataData.P6Power ? {
            type: "P6",
            value: parseFloat(billdataData.P6Power),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.P6Rate),
                currency: "€"
            }
        } : null,
    ].filter(item => item !== null);


    const exceeded_power = [
        billdataData.ExesosPower1 ? {
            type: puntaValue,
            value: parseFloat(billdataData.ExesosPower1),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ExesosRate1),
                currency: "€"
            }
        } : null,
        billdataData.ExesosPower2 ? {
            type: llanoValue,
            value: parseFloat(billdataData.ExesosPower2),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ExesosRate2),
                currency: "€"
            }
        } : null,
        billdataData.ExesosPower3 ? {
            type: "P6",
            value: parseFloat(billdataData.ExesosPower3),
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ExesosRate3),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);


    const reactive_power = [
        billdataData.ReactivaPower1 ? {
            type: "P",
            value: parseFloat(billdataData.ReactivaPower1),
            unit: `kVArh`,
            unit_price: {
                value: parseFloat(billdataData.ReactivaRate1),
                currency: "€"
            }
        } : null,
    ].filter(item => item !== null);

    const billed_period =
    {
        start: iniciocorrecto,
        end: finalcorrecto
    };

    const discount = [
        billdataData.DiscountAmount ? {
            price: {
                value: parseFloat(billdataData.DiscountAmount),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const other_concepts = [
        billdataData.ServiceAndOthersAmount ? {
            price: {
                value: parseFloat(billdataData.ServiceAndOthersAmount),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const equipment =
    {
        period: differenceInDays,
        unit_price: {
            value: parseFloat(billdataData.equipodemedidaprice),
            currency: "€"
        }
    }

    const supply_address =
    {
        location: suministroData.Dirección,
        postal_code: suministroData.CódigoPostal
    }

    const atr =
    {
        type: "TD",
        name: selectedServiceTypeElectricidad
    }

    const totalget = localStorage.getItem('total');

    const total =
    {
        price: {
            value: parseFloat(totalget ?? "0"),
            currency: "€"
        }
    };
    const jsonObject = {
        "marketer": "",
        "owner": "",
        "iban": "",
        "supply_address": supply_address,
        "cups": suministroData.CUPS,
        "atr": atr,
        "bill_period": billed_period,
        "consumed_powers": consumed_power,
        "billed_powers": billed_power,
        "reactive_powers": reactive_power,
        "exceeded_powers": exceeded_power,
        "discounts": discount,
        "equipment": equipment,
        "other_concepts": other_concepts,
        "taxes": null,
        "bono": {
            "period": 0,
            "unit_price": {
                "value": 0,
                "currency": "€"
            }
        },
        "total": total,
        "power_description": [],
    };
    return postBill(jsonObject);
};

export const jsonFormatgas = async () => {
    let suministro, billdata, selectedServiceTypeGas;
    suministro = localStorage.getItem('formDataGasCarga Comercial/Residencial');
    billdata = localStorage.getItem('formDataCargaGas');
    selectedServiceTypeGas = localStorage.getItem('selectedServiceTypeGas');

    if (!suministro || !billdata || !selectedServiceTypeGas) {
        console.error("Suministro or billdata is undefined.");
        return null;
    }

    const match = selectedServiceTypeGas.match(/RL(\d+)/);
    selectedServiceTypeGas = match ? match[1] : null;

    const suministroData = JSON.parse(suministro);
    const billdataData = JSON.parse(billdata);

    const storedInicio = new Date(suministroData.Inicio);
    const storedFin = new Date(suministroData.final);
    const differenceInMilliseconds = storedFin.getTime() - storedInicio.getTime();
    const differenceInDays = Math.trunc(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    const iniciocorrecto = formatDate(suministroData.Inicio);
    const finalcorrecto = formatDate(suministroData.final);

    const billed_period =
    {
        start: iniciocorrecto,
        end: finalcorrecto
    };

    const discount = [
        billdataData.DiscountAmount ? {
            price: {
                value: parseFloat(billdataData.DiscountAmount),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const other_concepts = [
        billdataData.ServiceAndOthersAmount ? {
            price: {
                value: parseFloat(billdataData.ServiceAndOthersAmount),
                currency: "€"
            }
        } : null
    ].filter(item => item !== null);

    const equipment =
    {
        period: differenceInDays,
        unit_price: {
            value: parseFloat(billdataData.equipodemedidaprice),
            currency: "€"
        }
    }

    const supply_address =
    {
        location: suministroData.Dirección,
        postal_code: suministroData.CódigoPostal
    }

    const atr =
    {
        type: "RL",
        name: selectedServiceTypeGas
    }

    const totalget = localStorage.getItem('total');

    const total =
    {
        price: {
            value: parseFloat(totalget || "0"),
            currency: "€"
        }
    }
    const consumed_gas =
        [{
            type: "Termino Fijo",
            value: parseFloat(billdataData.PuntaPower),
            unit: "kWh",
            unit_price: {
                value: parseFloat(billdataData.PuntaRate),
                currency: "€"
            }
        }]

    const termino_fijo_gas = 
        [{
            period: differenceInDays,
            unit_price: {
                value: parseFloat(billdataData.ValleRate),
                currency: "€"
            }
        }]

    const jsonObject = {
        "marketer": "",
        "owner": "",
        "iban": "",
        "supply_address": supply_address,
        "cups": suministroData.CUPS,
        "atr": atr,
        "bill_period": billed_period,
        "consumed_powers": consumed_gas,
        "billed_powers": termino_fijo_gas,
        "discounts": discount,
        "equipment": equipment,
        "other_concepts": other_concepts,
        "taxes": null,
        "bono": {
            "period": 0,
            "unit_price": {
                "value": 0,
                "currency": "€"
            }
        },
        "total": total
    };
    return postBill(jsonObject);
};