import '../assets/styles/UserIndividualCard.css';
import {Card, Col, message, Popconfirm, Row} from 'antd';
import {useEffect, useState} from 'react';

interface Product {
    _id: string;
    marketer: string;
    product_service: string;
    product_name: string;
    product_atr: string;
    fare: string;
    priority: number; // Change 'int' to 'number'
    power: number[];  // Array of numbers
    energy: number[]; // Array of numbers
}

interface ProductIndividualCardProps {
    product: Product;
    onDelete: (id: string) => void;
}

export const ProductIndividualCard = ({ product, onDelete }: ProductIndividualCardProps) => {
    const [screenSize, setScreenSize] = useState<string>('');

    const handleDeleteProduct = (id: string) => {
        onDelete(id);
        message.success('Producto eliminado exitosamente')
    };

    const confirm = (id: string) => {
        handleDeleteProduct(id)
    };
    
    const cancel = () => {
        message.error('Se canceló la eliminación');
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 800) {
                setScreenSize('extrasmallscreen');
            } else if (width < 1150) {
                setScreenSize('smallscreen');
            } else {
                setScreenSize('');
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    return (
        <>
            <Card className='userRow'>
                <Row key={product._id}  >
                    <Col md={4} sm={3} xs={3} className='username-col'>
                        {screenSize === 'smallscreen' && (
                            <a>Prioridad:<a className='username'>{product.priority}</a></a>
                        )}
                        {screenSize === 'extrasmallscreen' && (
                            <a className='username'>{product.priority}</a>
                        )}
                        {screenSize === '' && (
                            <a>Prioridad:<a className='username'>{product.priority}</a></a>
                        )}
                    </Col>
                    <Col className='separator'>
                    </Col>
                    <Col span={7} className='role-col'>
                        {screenSize === 'smallscreen' && (
                            <a>Distrib:<a className='role'>{product.marketer}</a></a>
                        )}
                        {screenSize === 'extrasmallscreen' && (
                            <a className='role'>{product.marketer}</a>
                        )}
                        {screenSize === '' && (
                            <a>Distruibuidora:<a className='role'>{product.marketer}</a></a>
                        )}
                    </Col>
                    <Col className='separator'>
                    </Col>
                    <Col span={7} className='role-col'>
                        {screenSize === 'smallscreen' && (
                            <a>Producto:<a className='role'>{product.product_name}</a></a>
                        )}
                        {screenSize === 'extrasmallscreen' && (
                            <a className='role'>{product.product_name}</a>
                        )}
                        {screenSize === '' && (
                            <a>Producto:<a className='role'>{product.product_name}</a></a>
                        )}
                    </Col>
                    <Col className='separator'>
                    </Col>
                    <Col className='delete-col'>
                            <Card className='eliminateCard'>
                                <Popconfirm
                                    title="Eliminar Producto"
                                    description="Seguro que desea eliminarlo?"
                                    onConfirm={() => confirm(product._id)}
                                    onCancel={cancel}
                                    okText="Si"
                                    cancelText="No"
                                    className='delete'
                                >
                                    <a className='delete'>{screenSize !== '' ? 'Eliminar' : 'Eliminar producto'}</a>
                                </Popconfirm>
                            </Card>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default ProductIndividualCard;