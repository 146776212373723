import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  GetRef,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table
} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import React, {useRef, useState} from "react";
import '../assets/styles/AddProduct.css';


interface DataType {
  key: React.Key;
  period: string;
  price: string;
}
type InputRef = GetRef<typeof Input>;

function AddProduct(): JSX.Element {
  const [items, setItems] = useState<string[]>(['Nabalia', 'Iberdrola']);
  const [name, setName] = useState<string>('');
  const [marketer, setMarketer] = useState<string>('');
  const inputRef = useRef<InputRef>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;


  const onNewMarketerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onMarketerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarketer(String(e));
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([...items, name || `New item `]);
    setName('');
  };

  const [dataSourcePotencia, setDataSourcePotencia] = useState<DataType[]>([
    {
      key: '0',
      period: 'P1',
      price: '0,',
    },
    {
      key: '1',
      period: 'P2',
      price: '0,',
    },
  ]);

  const [dataSourceEnergia, setDataSourceEnergia] = useState<DataType[]>([
    {
      key: '0',
      period: 'P1',
      price: '0,',
    },
  ]);

  const handleNewMarketerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleMarketerChange = (value: string) => {
    setMarketer(value);
  };

  const handleServiceChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleServiceTypeChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleFareChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleAddPotencia = () => {
    const newData: DataType = {
      key: String(dataSourcePotencia.length),
      period: `P${dataSourcePotencia.length + 1}`,
      price: '0,',
    };
    setDataSourcePotencia([...dataSourcePotencia, newData]);
  };

  const handleAddEnergia = () => {
    const newData: DataType = {
      key: String(dataSourceEnergia.length),
      period: `P${dataSourceEnergia.length + 1}`,
      price: '0,',
    };
    setDataSourceEnergia([...dataSourceEnergia, newData]);
  };

  const handleDeletePotencia = (index: number) => {
    setDataSourcePotencia(prevDataSource => {
      const newData = prevDataSource.filter((_, i) => i !== index);
      return newData.map((item, i) => ({ ...item, key: String(i) }));
    });
  };

  const handleDeleteEnergia = (index: number) => {
    setDataSourceEnergia(prevDataSource => {
      const newData = prevDataSource.filter((_, i) => i !== index);
      return newData.map((item, i) => ({ ...item, key: String(i) }));
    });
  };

  const handleFormSubmit = async () => {
    if (dataSourceEnergia.length === 2 || dataSourceEnergia.length === 4 || dataSourceEnergia.length === 5) {
      setModalVisible(true);
    } else {
      try {
        const formData = {
          marketer: marketer,
          product_service: form.getFieldValue('servicio'),
          product_name: form.getFieldValue('nombreProducto'),
          product_atr: form.getFieldValue('tipoServicio'),
          fare: form.getFieldValue('tarifa'),
          priority: form.getFieldValue('prioridad'),
          power: dataSourcePotencia.map(item => ({ type: item.period, price: { value: parseFloat(item.price.replace(',', '.')), currency: '€' } })),
          energy: dataSourceEnergia.map(item => ({ type: dataSourceEnergia.length === 1 ? 'Termino Fijo' : item.period, price: { value: parseFloat(item.price.replace(',', '.')), currency: '€' } }))
        };
        const response = await fetch(`${REACT_APP_BFF_URL}/api/products`, {
          method: 'POST',
          credentials: "include",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          message.success('Producto creado correctamente');
        } else {
          throw new Error('Failed to submit form');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        message.error('Hubo un problema con el producto, Intente nuevamente.');
      }
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const columnsPotencia = [
    {
      title: 'Periodo',
      dataIndex: 'period',
      width: '40%',
      render: (text: string, record: DataType, index: number) => ( 
        <Form.Item
          style={{ margin: 0 }}
          name={`period_potencia${index}`}
          initialValue={text}
          rules={[
            {
              required: true,
              message: ``,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const regexPattern = /^P[1-6]$/;
                if (regexPattern.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(``));
              },
            }),
          ]}
        >
          <Input
            value={text}
            onChange={(e) => {
              const newData = [...dataSourcePotencia];
              newData[index].period = e.target.value;
              setDataSourcePotencia(newData);
            }}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      width: '40%',
      render: (text: string, record: DataType, index: number) => (
        <Form.Item
          style={{ margin: 0 }}
          name={`price_potencia${index}`}
          initialValue={text}
          rules={[
            {
              required: true,
              message: ``,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const regexPattern = /^\d+(,\d+)*$/;
                if (regexPattern.test(value) && value !== "0") {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(``));
              },
            }),
          ]}
        >
          <Input
            value={text}
            onChange={(e) => {
              const newData = [...dataSourcePotencia];
              newData[index].price = e.target.value;
              setDataSourcePotencia(newData);
            }}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      render: (_: any, record: DataType, index: number) => {
        if (index === dataSourcePotencia.length - 1 && dataSourcePotencia.length > 2) {
          return (
            <Popconfirm
              title="Eliminar"
              description="¿Estás seguro?"
              onConfirm={() => handleDeletePotencia(Number(record.key))}
              okText="Sí"
              cancelText="Cancelar"
            >
              <Button type="link" danger className="butondelete">
                Eliminar
              </Button>
            </Popconfirm>
          );
        }
        return null;
      }
    }
  ];

  const columnsEnergia = [
    {
      title: 'Periodo',
      dataIndex: 'period',
      width: '40%',
      render: (text: string, record: DataType, index: number) => (
        <Form.Item
          style={{ margin: 0 }}
          name={`period_electricidad${index}`}
          initialValue={text}
          rules={[
            {
              required: true,
              message: ``,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const regexPattern = /^P[1-6]$/;
                if (regexPattern.test(value) && value !== "0") {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(``));
              },
            }),
          ]}
        >
          <Input
            value={text}
            onChange={(e) => {
              const newData = [...dataSourceEnergia];
              newData[index].period = e.target.value;
              setDataSourceEnergia(newData);
            }}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      width: '40%',
      render: (text: string, record: DataType, index: number) => (
        <Form.Item
          style={{ margin: 0 }}
          name={`price_electricidad${index}`}
          initialValue={text}
          rules={[
            {
              required: true,
              message: ``,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const regexPattern = /^\d+(,\d+)*$/;
                if (regexPattern.test(value) && value !== "0") {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(``));
              },
            }),
          ]}
        >
          <Input
            value={text}
            onChange={(e) => {
              const newData = [...dataSourceEnergia];
              newData[index].price = e.target.value;
              setDataSourceEnergia(newData);
            }}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      render: (_: any, record: DataType, index: number) => {
        if (index === dataSourceEnergia.length - 1 && dataSourceEnergia.length > 1) {
          return (
            <Popconfirm
              title="Eliminar"
              description="¿Estás seguro?"
              onConfirm={() => handleDeleteEnergia(Number(record.key))}
              okText="Sí"
              cancelText="Cancelar"
            >
              <Button type="link" danger className="butondelete">
                Eliminar
              </Button>
            </Popconfirm>
          );
        }
        return null;
      }
    }
  ];

  return (
    <Card className={'cardProduct'}>
      <Card title={'AGREGAR UN NUEVO PRODUCTO'}>
        <Form form={form} onFinish={handleFormSubmit}>
          <Row className="contentalign">
            <Col xs={24} sm={24} md={10} lg={10} xl={12} className="textaligntoleft">
              <a className="totheleft">Comercializadora</a>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={12} >
              <Form.Item style={{ margin: 0 }} name="comercializadora" rules={[{ required: true, message: '' }]}>
                <Select
                  placeholder="comercializadora"
                  onChange={(e) => onMarketerChange(e)}
                  className="width"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                          placeholder="Ingrese el item"
                          ref={inputRef}
                          value={name}
                          onChange={onNewMarketerChange}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                          Agregar
                        </Button>
                      </Space>
                    </>
                  )}
                  options={items.map((item) => ({ label: item, value: item }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="contentalign">
            <Col xs={24} sm={24} md={10} lg={10} xl={12} className="textaligntoleft">
              <a>Servicio</a>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={12}>
              <Form.Item style={{ margin: 0 }} name="servicio" rules={[{ required: true, message: '' }]}>
                <Select
                  onChange={handleServiceChange}
                  placeholder="servicio"
                  className="width"
                  options={[
                    { value: 'E', label: 'Electricidad' },
                    { value: 'G', label: 'Gas' }
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="contentalign">
            <Col xs={24} sm={24} md={10} lg={10} xl={12} className="textaligntoleft">
              <a>Nombre del producto</a>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={12}>
              <Form.Item style={{ margin: 0 }} name="nombreProducto" rules={[{ required: true, message: '' }]}>
                <Input className="width" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="contentalign">
            <Col xs={24} sm={24} md={10} lg={10} xl={12} className="textaligntoleft">
              <a>Tipo de Servicio</a>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={12}>
              <Form.Item style={{ margin: 0 }} name="tipoServicio" rules={[{ required: true, message: '' }]}>
                <Select
                  className="width"
                  onChange={handleServiceTypeChange}
                  placeholder="tipo de servicio"
                  options={[
                    { value: '2.0TD', label: '2.0TD' },
                    { value: '3.0TD', label: '3.0TD' },
                    { value: '6.1TD', label: '6.1TD' },
                    { value: 'RL.1', label: 'RL.1' },
                    { value: 'RL.2', label: 'RL.2' },
                    { value: 'RL.3', label: 'RL.3' },
                    { value: 'RL.4', label: 'RL.4' },
                    { value: 'RL.5', label: 'RL.5' },
                    { value: 'RL.6', label: 'RL.6' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="contentalign">
            <Col xs={24} sm={24} md={10} lg={10} xl={12} className="textaligntoleft">
              <a>Tarifa</a>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={12}>
              <Form.Item style={{ margin: 0 }} name="tarifa" rules={[{ required: true, message: '' }]}>
                <Select
                  className="width"
                  onChange={handleFareChange}
                  placeholder="tarifa"
                  options={[
                    { value: 'Tarifa Fija', label: 'Fija' },
                    { value: 'Tarifa Indexada', label: 'Indexada' },
                    { value: 'Tarifa Fija Revisable Trimestral OMIP', label: 'Fija Revisable Trimestral OMIP' }
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="contentalign">
            <Col xs={24} sm={24} md={10} lg={10} xl={12} className="textaligntoleft">
              <a>Prioridad</a>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={12}>
              <Form.Item style={{ margin: 0 }} name="prioridad" rules={[{ required: true, message: '' }]}>
                <Input className="width" type={'number'} />
              </Form.Item>
            </Col>
          </Row>
          <hr />
          <Row gutter={[16, 16]}>
            <a className="sombra">Potencia</a>
            <Col span={24}>
              <Table
                bordered
                dataSource={dataSourcePotencia}
                columns={columnsPotencia}
                pagination={false}
                className="tabla"
              />
              {dataSourcePotencia.length < 6 && (
                <Button onClick={handleAddPotencia} type="primary" className="buttonaddrow">
                  Agregar Periodo Potencia
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          <Row gutter={[16, 16]}>
            <a className="sombra">Energía</a>
            <Col span={24}>
              <Table
                bordered
                dataSource={dataSourceEnergia}
                columns={columnsEnergia}
                pagination={false}
                className="tabla"
              />
              {dataSourceEnergia.length < 6 && (
                <Button onClick={handleAddEnergia} type="primary" className="buttonaddrow">
                  Agregar Periodo Energía
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          <Row justify={'center'}>
            <Button htmlType="submit" type="primary" className="buttoncont">
              Continuar
            </Button>
          </Row>
        </Form>
      </Card>
      <Modal
        title="Error"
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="ok" type="primary" className="modalbutton" onClick={handleCloseModal}>
            Ok
          </Button>
        ]}
      >
        <p>La cantidad de filas en la sección de Energía debe ser 1, 3, o 6.</p>
      </Modal>
    </Card>

  );
}

export default AddProduct;