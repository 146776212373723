const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const generateRecommendation = async (billId: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/recommendations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({billId: billId}),
    })
    if (!response.ok) {
      throw new Error('Failed to generate recommendation');
    }

    return await response.json()
  } catch (err) {
    console.error('Error fetching recommendation:', err);
    return null;
  }
}

export const getRecommendationById = async (recommendationId: string) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/recommendations/${recommendationId}`)
    if (!response.ok) {
      throw new Error('Failed to fetch recommendation');
    }

    return await response.json()
  } catch (err) {
    console.error('Error fetching bill:', err);
    return null;
  }
}

export const updateRecommendation = async (recommendationId: string, index: any) => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/recommendations/${recommendationId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({selectedProduct: index})
    })
    if (!response.ok) {
      throw new Error('Failed to update recommendation');
    }

    return await response.json()
  } catch (err) {
    console.error('Error updating recommendation:', err);
    return null;
  }
}