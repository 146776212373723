import "../assets/styles/RecommendBox.css";
import {Button, message, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';
import {PDFDownloadLink} from '@react-pdf/renderer';
import React, {useEffect, useState} from 'react';
import {updateRecommendation} from "../services/RecommendationServices";

export const RecommendBox = ({recommendationData, recommendationId, pdfComponent, index}: {
  recommendationData: any,
  recommendationId: any,
  pdfComponent: JSX.Element,
  index: number
}): JSX.Element => {
  const navigate = useNavigate();
  const [pdfLoading, setPdfLoading] = useState(true);

  useEffect(() => {
    if (recommendationData) {
      setPdfLoading(false);
    }
  }, [recommendationData]);

  const handleFormSubmit = async () => {
    const selectedUser = localStorage.getItem('selectedUsuario');
    if (selectedUser === 'residencial' || selectedUser === 'comercial') {
      await updateRecommendation(recommendationId, index);
      navigate(`/${selectedUser}/contrato/cliente`);
      message.success('Recomendación seleccionada');
    } else {
      console.log('Selected service not found.');
    }
  };

  return (
    <div className="box rightbox">
      <div className="nueva">
        <div className="overlap-group-nueva">
          {recommendationData ? (
            <>
              <div className="overlap-nueva">
                <div className="text-wrapper">RECOMENDACION</div>
                <div className="tarifa-type">{recommendationData?.product_fare  || "Tipo de Tarifa"}</div>
              </div>
              <div className="div">{recommendationData.product_marketer || "Empresa Recomendada"}</div>
              
              <div className="text-wrapper-7">Potencia</div>
              <div className="text-wrapper-8"><span className="azul">{recommendationData?.power?.total_product || "Potencia "}€</span></div>
              <div className="text-wrapper-7">Energia</div>
              <div className="text-wrapper-8"><span className="azul">{recommendationData?.energy?.total_product || "Energia "}€</span></div>
              <div className="text-wrapper-7">Total factura</div>
              <div className="text-wrapper-6"><span className='azul'>{recommendationData?.total?.product || "Total "}€</span></div>
              <div className="text-wrapper-7">Porcentaje de ahorro</div>
              <div className="text-wrapper-2"><span className="azul">-{recommendationData?.savings_pct || "Porcentaje"}%</span></div>
              <div className="text-wrapper-7">Ahorro total</div>
              <div className="text-wrapper-4"><span className="azul">-{recommendationData?.savings || "Ahorro "}€</span></div>
              <PDFDownloadLink
                document={pdfComponent}
                fileName="Recomendacion.pdf"
              >
                {({ loading }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    setPdfLoading(loading);
                  }, [loading]);
                  
                  return (
                    <Button className="recommendation-button">
                      {loading ? (
                        <>
                          Generando
                          <Spin size="default" className='spin' />
                        </>
                      ) : (
                        'Descargar PDF'
                      )}
                    </Button>
                  );
                }}
              </PDFDownloadLink>
              <Button className="select-button" onClick={handleFormSubmit} disabled={pdfLoading}>
                Seleccionar
              </Button>
            </>
          ) : (
            <div className="spinner">
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendBox;
