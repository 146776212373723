import RecommendBoxNew from '../components/RecommendBoxNew';
import '../assets/styles/RecommendBox.css';
import {Col, Row} from 'antd';
import ElectricityPDF from './ElectricityPDF';
import React, {useEffect, useState} from 'react';
import {generateRecommendation} from '../services/RecommendationServices';
import RecommendBoxActual from '../components/RecommendBoxActual';
import GasPDF from './GasPDF';
import {createReport} from "../services/ReportServices";
import NoRecommendationComponent from "../components/NoRecommendationComponent";

function RecommendationPage() {
  const billId = localStorage.getItem('insertedId');
  const service = localStorage.getItem('selectedService');
  const [recommendationData, setRecommendationData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState<boolean>(false); // State for "No recommendation found"

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setNotFound(false);
      if (billId) {
        try {
          const res = await generateRecommendation(billId);
          if (res.message && res.message === "No recomendation found") {
            setNotFound(true);
          } else {
            setRecommendationData(res);
          }
        } catch (error) {
          setNotFound(true);
          console.error('Error fetching recommendation:', error);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [billId]);

  useEffect(() => {
    const fetchData = async () => {
      if (recommendationData) {
        try {
          await createReport(recommendationData.id);
        } catch (error) {
          console.error('Error creating report:', error);
        }
      }
    };
    fetchData();
  }, [recommendationData]);

  if (notFound) {
    return <NoRecommendationComponent/>;
  }

  return (
    <body>
    <Row className="middle">
      <Col span={24} className='align'>
        <a className="titulin">El ahorro depende de ti</a>
      </Col>
      <Col>
        <RecommendBoxActual recommendationData={recommendationData?.recommendations[0]}/>
      </Col>
      {recommendationData?.recommendations.map((recommendation: any, index: number) => {
        const pdfComponent = service === 'electricidad' ? (
          <ElectricityPDF recommendationData={recommendation}/>
        ) : (
          <GasPDF recommendationData={recommendation}/>
        );
        return (
          <Col key={index}>
            <RecommendBoxNew
              recommendationData={recommendation}
              recommendationId={recommendationData.id}
              pdfComponent={pdfComponent}
              index={index}
            />
          </Col>
        );
      })}
    </Row>
    </body>
  );
}

export default RecommendationPage;
