const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const createReport = async (recommendationId: string) => {
  if (localStorage.getItem('selectedUsuario') === 'residencial') {

    try {
      const formDataResidencial = localStorage.getItem('formDataResidential');
      if (!formDataResidencial) {
        throw new Error('formDataResidential is missing in localStorage');
      }

      const formDataJSON = JSON.parse(formDataResidencial);
      const response = await fetch(`${REACT_APP_BFF_URL}/api/reports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "first_name": formDataJSON.Nombre,
          "last_name": formDataJSON.Apellido,
          "user_type": localStorage.getItem('selectedUsuario'),
          "phone_number": formDataJSON['Teléfono'],
          "email": formDataJSON.Email,
          "recommendations_id": recommendationId
        }),
      })
      if (!response.ok) {
        throw new Error('Failed to create report');
      }

      return await response.json()
    } catch (err) {
      console.error('Error creating report:', err);
      return null;
    }


  } else if (localStorage.getItem('selectedUsuario') === 'comercial') {

    try {
      const formDataCommercial = localStorage.getItem('formDataCommercial');
      if (!formDataCommercial) {
        throw new Error('formDataCommercial is missing in localStorage');
      }

      const formDataJSON = JSON.parse(formDataCommercial);
      const response = await fetch(`${REACT_APP_BFF_URL}/api/reports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "first_name": formDataJSON.Nombre,
          "last_name": formDataJSON.Apellido,
          "user_type": localStorage.getItem('selectedUsuario'),
          "business_name": formDataJSON["Razón Social"],
          "position": formDataJSON.Cargo,
          "phone_number": formDataJSON['Teléfono'],
          "email": formDataJSON.Email,
          "recommendations_id": recommendationId
        }),
      })
      if (!response.ok) {
        throw new Error('Failed to create report');
      }

      return await response.json()
    } catch (err) {
      console.error('Error creating report:', err);
      return null;
    }
  }
}

export const getReports = async () => {
  try {
    const response = await fetch(`${REACT_APP_BFF_URL}/api/reports`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch reports');
    }

    return await response.json();
  } catch (err) {
    console.error('Error fetching reports:', err);
    return null;
  }
};
