import '../assets/styles/Login.css';
import {Button, Card, Form, Input, message, Select} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import type {SizeType} from 'antd/es/config-provider/SizeContext';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';


interface UserData {
    users: string[];
}
function Signin(): JSX.Element {
    const [size, setSize] = useState<SizeType>('middle');
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

    const handleFormSubmit = async () => {
        form.validateFields()
            .then(async (values) => {
                const userData = {
                    username: values.Usuario,
                    password: values.Contrasenia,
                    role: values.Rol
                };
                try {
                    const response = await fetch(`${REACT_APP_BFF_URL}/api/auth/register`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                        body: JSON.stringify(userData),
                    });
                    
                    if (response.ok) {
                        message.success('Se registró correctamente');
                    } else {
                        message.error('Ya existe un usuario con ese nombre');
                    }
                } catch (error) {
                    console.error('Error during login:', error);
                    message.error('Los datos son incorrectos');
                }
            })
            .catch((error) => {
                console.error('Form validation error:', error);
            });
    };

        const validateReContraseña = (_: any, value: string) => {
            const contraseñaFieldValue = form.getFieldValue('Contrasenia');
            if (value !== contraseñaFieldValue) {
                return Promise.reject(new Error('Las contraseñas no coinciden'));
            }
            return Promise.resolve();
        };


        return (
            <Form
                form={form}
                name="control-hooks"
            >
                <Card className="card-iniciar-sesion" bordered={true}>
                    Registrarse
                    <Form.Item name="Usuario" rules={[{ required: true, message: 'Ingrese su usuario' }]}>
                        <Input
                            placeholder="Usuario"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                    <Form.Item name="Contrasenia" rules={[{ required: true, message: 'Ingrese su contraseña' }]}>
                        <Input.Password
                            placeholder="Contraseña"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="ReContrasenia"
                        rules={[
                            { required: true, message: 'Ingrese nuevamente su contraseña' },
                            { validator: validateReContraseña },
                        ]}
                    >
                        <Input.Password
                            placeholder="Repita su contraseña"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                    <Form.Item name="Rol" rules={[{ required: true, message: 'Seleccione el Rol' }]}>
                        <Select
                            placeholder="Rol"
                            style={{ width: 150 }}
                            allowClear
                            options={[{ value: 'ADMIN', label: 'Administrador' }, { value: 'Consultor', label: 'Consultor' }]}
                        />
                    </Form.Item>
                    <Button type="primary" shape="round" size={size} className="button" htmlType="submit" onClick={handleFormSubmit}>
                        Iniciar
                    </Button>

                </Card>
            </Form>
        );
    }

    export default Signin;
