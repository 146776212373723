import React, {useState} from 'react';
import {Button, Card, Form, message, Modal, Row, Upload} from 'antd';
import {Container} from 'react-bootstrap';
import {InboxOutlined} from '@ant-design/icons';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';


function BillLoad(): JSX.Element {
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isUploadFailed, setIsUploadFailed] = useState(false);
    const navigate = useNavigate();
    const { Dragger } = Upload;
    const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

    const handleCloseModal = () => {
        setIsUploadFailed(false);
      };

    const handleFormSubmit = () => {
        if (isFileUploaded) {
            const selectedService = localStorage.getItem('selectedService');
            if (selectedService === 'electricidad' || selectedService === 'gas') {
                const selectedUser = localStorage.getItem('selectedUsuario');
                if (selectedUser === 'residencial' || selectedUser === 'comercial') {
                    navigate(`/recomendacion`);
                } else {
                    console.log('Selected user type not found.');
                }
            } else {
                console.log('Selected service not found.');
            }
        } else {
            message.error('Por favor, carga un archivo PDF.');
        }
    };

    return (
        <Container>
            <Form name="control-hooks">
                <Card className="cardconsult" bordered={true}>
                    <p>Adjunta una factura</p>
                    <Dragger
                        name="file"
                        multiple={false}
                        action={`${REACT_APP_BFF_URL}/api/billReadings/upload-bill`}
                        accept=".pdf"
                        customRequest={(options: any) => {
                            const data = new FormData();
                            data.append('file', options.file);
                            data.append('type', `${localStorage.getItem('selectedService')}`);
                            axios
                                .post(options.action, data)
                                .then((res: any) => {
                                    localStorage.setItem('insertedId', res.data.insertedId);
                                    localStorage.removeItem('recommendationId');
                                    console.log('res', res);
                                    options.onSuccess(res.data, options.file);
                                    setIsFileUploaded(true);
                                    setIsUploadFailed(false); // Reset upload failure status
                                })
                                .catch((err: Error) => {
                                    console.log(err);
                                    setIsUploadFailed(true); // Set upload failure status
                                });
                        }}
                        beforeUpload={(file: File) => {
                            const isPDF = file.type === 'application/pdf';
                            if (!isPDF) {
                                message.error(`${file.name} no es un archivo PDF.`);
                            }
                            return isPDF;
                        }}
                        onChange={(info: any) => {
                            const { status } = info.file;
                            if (status === 'done') {
                                message.success(`${info.file.name} cargado exitosamente.`);
                            } else if (status === 'error') {
                                message.error(`${info.file.name} no se pudo cargar.`);
                            }
                        }}
                        maxCount={1}
                        className="uploadborder"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined className="green" />
                        </p>
                        <p className="ant-upload-text">Arrastra o haz click para subir tu factura</p>
                        <p className="ant-upload-hint">Formatos aceptados: PDF</p>
                    </Dragger>
                    <Button
                        type="primary"
                        shape="round"
                        className={`buttoncontdisabled `}
                        onClick={handleFormSubmit}
                        disabled={!isFileUploaded}
                    >
                        Continuar
                    </Button>
                    {isUploadFailed && (
                        <Modal
                        title="Oops, No pudimos extraer los datos del documento."
                        open={isUploadFailed}
                        onOk={handleCloseModal}
                        onCancel={handleCloseModal}
                        footer={[
                          <Row className='buttonsRow'>
                            <Button
                              className='modalbuttonwpp'
                              onClick={handleCloseModal}
                            >
                              <a className='pointer' >Continuar</a>
                            </Button></Row>
              
                        ]}
                      >
                        <p>Esto puede ser debido a que el documento cargado no sea una factura correcta.</p>
                      </Modal>
                    )}
                </Card>
            </Form>
        </Container>
    );
}

export default BillLoad;
