import React, {ReactNode} from 'react';
import {Navigate} from 'react-router-dom';
import Cookies from 'universal-cookie';

interface AdminRouteProps {
  children: ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const cookies = new Cookies();
  const accessToken = cookies.get('access-token');
  const isLoggedIn = !!accessToken;
  let tokenInfo;
  if (isLoggedIn) {
    tokenInfo = JSON.parse(atob(accessToken.split('.')[1]));
  }

  return tokenInfo && tokenInfo.role === 'ADMIN' ? (children as React.ReactElement) : <Navigate to='/404' />;
};

export default AdminRoute;