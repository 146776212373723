import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const cookies = new Cookies();
  const accessToken = cookies.get('access-token');
  const isLoggedIn = !!accessToken;

  return isLoggedIn ? (children as React.ReactElement) : <Navigate to='/404' />;
};

export default PrivateRoute;