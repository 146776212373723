import '../assets/styles/ContratoDatosResidencial.css';
import { Input, Form, Card, Col, Row, Select } from 'antd';
import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading'

function ContratoDatosResidencial(): JSX.Element {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const storedFormData = localStorage.getItem('ContratoDatosResidencial');
    const morestored = localStorage.getItem('formDataElectricidadCarga Residencial');
    const parsedFormData = storedFormData ? JSON.parse(storedFormData) : JSON.parse(morestored || '{}');
    const [poblacion, setPoblacion] = useState<string>(parsedFormData.Población || '');
    const [provincia, setProvincia] = useState<string>(parsedFormData.Provincia || '');
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const { Option } = Select;
    const [loading, setLoading] = useState(false);

    const handleInputChange = (fieldName: string, value: string) => {
        form.setFieldsValue({ [fieldName]: value });
        const formValues = form.getFieldsValue();
        localStorage.setItem('ContratoDatosResidencial', JSON.stringify(formValues));
    };

    const handleFormSubmit = async () => {
        setLoading(true);
        setFormSubmitted(true);
        await handleCodigoPostalChange(form.getFieldValue('Código Postal'));
        try {
            await form.validateFields();
            navigate(`/residencial/contrato/tarifaacceso`);
        } catch (error) {
            console.log('Please complete all required form fields.', error);
        } finally {
            setLoading(false);
        }
    };
    const handleCodigoPostalChange = async (value: string) => {
        try {
            const response = await axios.get(`https://api.zippopotam.us/es/${value}`);
            const { places } = response.data;

            if (places && places.length > 0) {
                const { "place name": placeName, state } = places[0];

                setPoblacion(state);
                setProvincia(placeName);

                form.setFieldsValue({
                    Población: state,
                    Provincia: placeName,
                });

            } else {
                setPoblacion('');
                setProvincia('');

                form.setFieldsValue({
                    Población: '',
                    Provincia: '',
                });
            }

        } catch (error) {
            console.error('Error fetching data for Código Postal:', error);
        }
    };
    useEffect(() => {
        const moredatosprevformelectr = localStorage.getItem('formDataElectricidadCarga Residencial');
        const moredatosprevformgas = localStorage.getItem('formDataGasCarga Comercial/Residencial');
        const datosprevform = localStorage.getItem('formDataResidential');
        const storedFormData = localStorage.getItem('ContratoDatosResidencial');

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);
            form.setFieldsValue(parsedFormData);
        } else if (datosprevform) {
            const prevformData = JSON.parse(datosprevform);
            let moreprevformData = {};
            if (moredatosprevformelectr) {
                moreprevformData = JSON.parse(moredatosprevformelectr);
            } else if (moredatosprevformgas) {
                moreprevformData = JSON.parse(moredatosprevformgas);
            }

            const { Nombre, Apellido, ...rest } = prevformData;
            const nombreYApellido = `${Nombre} ${Apellido}`;
            const mergedFormData = {
                "Nombre y Apellidos": nombreYApellido,
                ...rest,
                ...moreprevformData
            };
            form.setFieldsValue(mergedFormData);
        }
    }, [form]);
    useEffect(() => {
        const storedFormData = localStorage.getItem('ContratoDatosResidencial');
        const moredatosprevformelectr = localStorage.getItem('formDataElectricidadCarga Residencial');
        const moredatosprevformgas = localStorage.getItem('formDataGasCarga Comercial/Residencial');

        if (storedFormData !== null) {
            const parsedFormData = JSON.parse(storedFormData);
            parsedFormData.Población = poblacion;
            parsedFormData.Provincia = provincia;
            localStorage.setItem('ContratoDatosResidencial', JSON.stringify(parsedFormData));
        } else if (moredatosprevformelectr !== null || moredatosprevformgas !== null) {
            const moreParsedFormData = moredatosprevformelectr ? JSON.parse(moredatosprevformelectr) : JSON.parse(moredatosprevformgas!);
            moreParsedFormData.Población = poblacion;
            moreParsedFormData.Provincia = provincia;
            localStorage.setItem('ContratoDatosResidencial', JSON.stringify(moreParsedFormData));
        }
    }, [poblacion, provincia]);

    return (
        <Container>
            {loading && <Loading />}
            <Form form={form} name="control-hooks" disabled={loading}>
                <Card className="card-residen-contrato" bordered={true}>
                    <a className='tituloo'>Solo unos datos mas...</a>
                    <p className='datos'>Datos del cliente</p>
                    <Col className='separate'>
                        <Row className='col-inputs paddings'>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Nombre y Apellidos"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const lettersRegex = /^[A-Za-z\s]+$/;
                                                const minThreeLetters = value.length >= 3;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!lettersRegex.test(value) || !minThreeLetters) {
                                                    return Promise.reject(new Error('No válidos'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nombre y Apellidos"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Nombre y Apellido', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="TipoDocumento"
                                    validateFirst
                                    className='margininput nextto'
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Select
                                        placeholder=""
                                        onChange={(value) => handleInputChange('TipoDocumento', value)}
                                        className='selection'
                                    >
                                        <Option value="DNI">DNI</Option>
                                        <Option value="NIF">NIF</Option>
                                        <Option value="NIE">NIE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="Documento"
                                    validateFirst
                                    className='margininput nextto'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const documentRegex = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!documentRegex.test(value)) {
                                                    return Promise.reject(new Error('Dato Inválido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Documento"
                                        className='input inputcontrat documento'
                                        onChange={(e) => handleInputChange('Documento', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='col-inputs paddings'>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Teléfono Fijo"
                                    validateFirst
                                    className='margininput'
                                    rules={[
                                        { required: false, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const phoneRegex = /^[0-9]{9}$/;

                                                if (value && !phoneRegex.test(value)) {
                                                    return Promise.reject(new Error('Teléfono no válido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Teléfono Fijo"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Teléfono Fijo', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Teléfono"
                                    validateFirst
                                    className='margininput'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const phoneRegex = /^[0-9]{9}$/;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!phoneRegex.test(value)) {
                                                    return Promise.reject(new Error('Teléfono no válido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Móvil"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Móvil', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row className='col-inputs paddings'>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Email"
                                    validateFirst
                                    className='margininput'
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!emailRegex.test(value)) {
                                                    return Promise.reject(new Error('Email no válido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="E-mail"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('E-mail', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Dirección"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: 'Ingrese su Dirección' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                if (!value) {
                                                    return Promise.reject(new Error('Ingrese su Dirección'));
                                                }

                                                const minThreeChars = value.length >= 3;

                                                if (!minThreeChars) {
                                                    return Promise.reject(new Error('Domicilio no válido'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Domiciliado en"
                                        className='input inputcontrat'
                                        onChange={(e) => handleInputChange('Domiciliado en', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row className='col-inputs paddings'>
                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Código Postal"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                // if (!formSubmitted) {
                                                //   return Promise.resolve();
                                                // }
                                                const Regex = /^[0-9]{5}$/;

                                                if (!Regex.test(value)) {
                                                    setPoblacion('');
                                                    setProvincia('');
                                                    form.setFieldsValue({
                                                        Población: '',
                                                        Provincia: '',
                                                    });
                                                    return Promise.reject(new Error('Código Postal Inválido'));
                                                }

                                                try {
                                                    const response = await axios.get(`https://api.zippopotam.us/es/${value}`);
                                                    const { places } = response.data;

                                                    if (places && places.length > 0) {
                                                        const { "place name": placeName, state } = places[0];

                                                        setPoblacion(state);
                                                        setProvincia(placeName);

                                                        form.setFieldsValue({
                                                            Población: state,
                                                            Provincia: placeName,
                                                        });


                                                        return Promise.resolve();
                                                    } else {
                                                        setPoblacion('');
                                                        setProvincia('');

                                                        form.setFieldsValue({
                                                            Población: '',
                                                            Provincia: '',
                                                        });

                                                        return Promise.reject('No place found for Código Postal: ' + value);
                                                    }
                                                } catch (error) {
                                                    console.error('Error fetching data for Código Postal:', error);
                                                    return Promise.reject('Código Postal Inválido');
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Código Postal"
                                        className='input inputcontrat'
                                        onChange={(e) => {
                                            handleInputChange('Código Postal', e.target.value);
                                            handleCodigoPostalChange(e.target.value);
                                            setErrorMsg(null);
                                        }}
                                    />
                                </Form.Item>

                                {errorMsg && <div className="error-message">{errorMsg}</div>}
                            </Col>

                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Provincia"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const lettersRegex = /^[A-Za-z\s]+$/;
                                                const minThreeLetters = value.length >= 3;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!lettersRegex.test(value) || !minThreeLetters) {
                                                    return Promise.reject(new Error('Provincia no válida'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Provincia"
                                        className='input inputcontrat uneditable'
                                        onChange={(e) => handleInputChange('Provincia', e.target.value)}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='col-inputs paddings'>

                            <Col className='inputpadding'>
                                <Form.Item
                                    name="Población"
                                    className='margininput'
                                    validateFirst
                                    rules={[
                                        { required: true, message: '' },
                                        {
                                            validator: async (_, value) => {
                                                if (!formSubmitted) {
                                                    return Promise.resolve();
                                                }

                                                const lettersRegex = /^[A-Za-z-\s]+$/;
                                                const minThreeLetters = value.length >= 3;

                                                if (!value) {
                                                    return Promise.reject(new Error(''));
                                                }

                                                if (!lettersRegex.test(value) || !minThreeLetters) {
                                                    return Promise.reject(new Error('Población no válida'));
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Población"
                                        className='input inputcontrat uneditable'
                                        onChange={(e) => handleInputChange('Población', e.target.value)}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Col>
                    <Button type="primary" shape="round" className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
                        Continuar
                    </Button>
                </Card>
            </Form>
        </Container>
    );
}

export default ContratoDatosResidencial;
