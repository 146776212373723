import '../assets/styles/MainMenu.css';
import { Form, Card, Col, Row } from 'antd';
import { ThunderboltFilled, FireFilled, HomeFilled, ShopFilled, ClockCircleOutlined, SafetyCertificateOutlined, LineChartOutlined } from '@ant-design/icons';
import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function MainMenu(): JSX.Element {
  const [form] = Form.useForm();
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedUsuario, setSelectedUsuario] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleServiceClick = (service: string) => {
    setSelectedService(service);
    form.setFieldsValue({ servicio: service });
  };

  const handleUsuarioClick = (usuario: string) => {
    setSelectedUsuario(usuario);
    form.setFieldsValue({ Usuario: usuario });
  };

  const handleFormSubmit = () => {
    if (selectedService && selectedUsuario) {
      localStorage.setItem('selectedService', selectedService);
      localStorage.setItem('selectedUsuario', selectedUsuario);
      localStorage.setItem('timestamp', new Date().getTime().toString());
      if(selectedService === 'electricidad'){
        localStorage.removeItem('formDataCargaGas');
        localStorage.removeItem('formDataGasCarga Comercial/Residencial');
        localStorage.removeItem('selectedServiceTypeGas');
      }else if (selectedService === 'gas'){
        localStorage.removeItem('formDataCarga2.0');
        localStorage.removeItem('formDataCarga3.0/6.1');
        localStorage.removeItem('selectedServiceTypeElectricidad');
      }
      if (selectedUsuario === 'residencial') {
        navigate('/residencial');
        localStorage.removeItem('ContratoDatosComercial');
        localStorage.removeItem('agreementCommercial');
        localStorage.removeItem('formDataElectricidadCarga Comercial');
        localStorage.removeItem('formDataCommercial');
        localStorage.removeItem('selectedServiceTypeElectricidad');
      } else if (selectedUsuario === 'comercial') {
        navigate('/comercial');
        localStorage.removeItem('ContratoDatosResidencial');
        localStorage.removeItem('agreementResidential');
        localStorage.removeItem('formDataElectricidadCarga Residencial');
        localStorage.removeItem('formDataResidential');
      }
    } 
  };

  useEffect(() => {
    const storedService = localStorage.getItem('selectedService');
    const storedUsuario = localStorage.getItem('selectedUsuario');

    if (storedService && storedUsuario) {
      setSelectedService(storedService);
      setSelectedUsuario(storedUsuario);
    }
  }, []);

  return (
    <Container>
      
      <div className="container-with-text">
        <p className="text-on-container">Compara servicios de Luz y Gas</p>
        <Row>
          <Col className='subtitlescol '><ClockCircleOutlined /><p className='subtitles'>Recomendaciones instantáneas</p></Col>
          <Col className='subtitlescol subtitlescol2'><SafetyCertificateOutlined /><p className='subtitles'>Asesoramiento de personal calificado</p></Col>
          <Col className='subtitlescol subtitlescol3'><LineChartOutlined /><p className='subtitles'>Ahorro significativo anualmente</p></Col>
        </Row>
      </div>
      <Form form={form} name="control-hooks">
        <Card className="card" bordered={true}>
          Tu Consulta:
          <Row className='separate'>
            <Form.Item
              name="servicio"
              rules={[
                {
                  required: true,
                  message: 'Porfavor seleccione un tipo de servicio',
                },
              ]}
            >
              <Row gutter={16} justify="center" align="middle" className='content-align'>
                <Col>
                  <div className='text pad-right'>Servicio:</div>
                </Col>
                <Col>
                  <Card
                    hoverable
                    className={`selectcards ${selectedService === 'electricidad' ? 'clicked' : ''}`}
                    onClick={() => handleServiceClick('electricidad')}
                  >
                    <div className='text'>
                      <ThunderboltFilled />  Electricidad
                    </div>
                  </Card>
                </Col>
                <Col>
                  <Card
                    hoverable
                    className={`selectcards ${selectedService === 'gas' ? 'clicked' : ''}`}
                    onClick={() => handleServiceClick('gas')}
                  >
                    <div className='text'>
                      <FireFilled /> Gas
                    </div>
                  </Card>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="Usuario"
              rules={[
                {
                  required: true,
                  message: 'Porfavor seleccione un tipo de Usuario',
                },
              ]}
            >
              <Row gutter={16} className='content-align content-align-right'>
                <Col>
                  <div className='text pad-right-inf'>Usuario:</div>
                </Col>
                <Col>
                  <Card
                    hoverable
                    className={`selectcards ${selectedUsuario === 'residencial' ? 'clicked' : ''}`}
                    onClick={() => handleUsuarioClick('residencial')}
                  >
                    <div className='text'>
                      <HomeFilled /> Residencial
                    </div>
                  </Card>
                </Col>
                <Col>
                  <Card
                    hoverable
                    className={`selectcards ${selectedUsuario === 'comercial' ? 'clicked' : ''}`}
                    onClick={() => handleUsuarioClick('comercial')}
                  >
                    <div className='text'>
                      <ShopFilled /> Comercial
                    </div>
                  </Card>
                </Col>
              </Row>
            </Form.Item>
          </Row>

          <Button type="primary" shape="round" className="buttoncont" htmlType="submit" onClick={handleFormSubmit}>
            Continuar
          </Button>
        </Card>
      </Form>
    </Container>
  );
}

export default MainMenu;