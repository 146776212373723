import Loading from '../components/Loading';
import { Card } from 'antd';
import { Container } from 'react-bootstrap';
import { getUsers } from '../services/UserServices';
import { useState, useEffect } from 'react';
import UserIndividualCard from '../components/UserIndividualCard';
import { deleteUser } from '../services/UserServices';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface User {
    _id: string;
    username: string;
    role: string;
}

function UserControl(): JSX.Element {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleDeleteUser = async (username: string) => {
        try {
            await deleteUser(username);
            setUsers(users.filter(user => user.username !== username));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleAddUser = async () => {
        navigate('/signin')
    };

    const handleBack = () => {
        navigate('/servicioaconsultar');
    };

    useEffect(() => {
        async function fetchUsers() {
            const usersData = await getUsers();
            if (usersData) {
                setUsers(usersData);
                setLoading(false);
            }
        }
        fetchUsers();
    }, []);

    return (
        <Container>
            <Card className="cardtable" bordered={true}>
                <CloseOutlined className='fixed-cross' onClick={handleBack} />
                <a>Gestión de Usuarios</a>
                {loading && <Loading />}
                {users.map(user => (
                    <UserIndividualCard key={user._id} user={user} onDelete={handleDeleteUser} />
                ))}
                <PlusCircleOutlined className='ADD' onClick={handleAddUser} />
            </Card>
        </Container>
    );
}

export default UserControl;
